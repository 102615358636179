export const formData_default = {
  mobile: '',
  yyuserid: '',
  sex: '',
  birth: '',
  examdate: '',
  examplace: '',

  corp: '',

  goods_id: '',
  goods_type: '',

  height: '',
  weight: '',
  // bust: '',
  waist: '',
  hip: '',
  fat: '',
  chest: '',

  area: '',
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    {
      kdis: 'K01',
      level: '0',
    },
    {
      kdis: 'K02',
      level: '0',
    },
    {
      kdis: 'K03',
      level: '0',
    },
    {
      kdis: 'K04',
      level: '0',
    },
    {
      kdis: 'K05',
      level: '0',
    },
    {
      kdis: 'K06',
      level: '0',
    },
    {
      kdis: 'K07',
      level: '0',
    },
    {
      kdis: 'K08',
      level: '0',
    },
    {
      kdis: 'K09',
      level: '0',
    },
    {
      kdis: 'K10',
      level: '0',
    },
    {
      kdis: 'K11',
      level: '0',
    },
    {
      kdis: 'K12',
      level: '0',
    },
    {
      kdis: 'K13',
      level: '0',
    },
    {
      kdis: 'K14',
      level: '0',
    },
    {
      kdis: 'K15',
      level: '0',
    },
    {
      kdis: 'K16',
      level: '0',
    },
    {
      kdis: 'K17',
      level: '0',
    },
    {
      kdis: 'K18',
      level: '0',
    },
    {
      kdis: 'K19',
      level: '0',
    },
  ],
  oper: [],
  famdis: [],

  preweek: '',
  preweight: '',
  prewaist: '',
  // prebust: '',
  prehip: '',
  prefat: '',

  bsex: '',
  mage: '',
  week: '',
  bweight: '',
  aps1: '',

  examine: [
    {
      ne: 'WBC',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'HGB',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'PLT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'FG',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'HBA1C',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'CHOL',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'LDLC',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'HDLC',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'TG',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'GOT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'GPT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'ALP',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'ALB',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'CRE',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'UA',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'EGFR',
      ve: '',
      ut: 'A',
    },
  ],

  predis2: [],

  operator: '', // 当前后台操作人手机号

  //骨折信息
  qnmh: '',
  qntimes: '',
  qnpbf: '',
  selfcare: '',
  tscore: '',
  fbdisease: [
    {
      fdis: 'F00',
      level: '0',
    },
    {
      fdis: 'F01',
      level: '0',
    },
    {
      fdis: 'F02',
      level: '0',
    },
    {
      fdis: 'F03',
      level: '0',
    },
    {
      fdis: 'F04',
      level: '0',
    },
    {
      fdis: 'F05',
      level: '0',
    },
    {
      fdis: 'F06',
      level: '0',
    },
    {
      fdis: 'F07',
      level: '0',
    },
    {
      fdis: 'F08',
      level: '0',
    },
    {
      fdis: 'F09',
      level: '0',
    },
    {
      fdis: 'F10',
      level: '0',
    },
    {
      fdis: 'F11',
      level: '0',
    },
    {
      fdis: 'F12',
      level: '0',
    },
    {
      fdis: 'F13',
      level: '0',
    },
    {
      fdis: 'F14',
      level: '0',
    },
    {
      fdis: 'F15',
      level: '0',
    },
    {
      fdis: 'F16',
      level: '0',
    },
    {
      fdis: 'F17',
      level: '0',
    },
    {
      fdis: 'F18',
      level: '0',
    },
    {
      fdis: 'F19',
      level: '0',
    },
    {
      fdis: 'F20',
      level: '0',
    },
    {
      fdis: 'F21',
      level: '0',
    },
    {
      fdis: 'F22',
      level: '0',
    },
    {
      fdis: 'F23',
      level: '0',
    },
    {
      fdis: 'F24',
      level: '0',
    },
    {
      fdis: 'F25',
      level: '0',
    },
    {
      fdis: 'F26',
      level: '0',
    },
    {
      fdis: 'F27',
      level: '0',
    },
    {
      fdis: 'F28',
      level: '0',
    },
    {
      fdis: 'F29',
      level: '0',
    },
    {
      fdis: 'F30',
      level: '0',
    },
    {
      fdis: 'F31',
      level: '0',
    },
    {
      fdis: 'F32',
      level: '0',
    },
    {
      fdis: 'F33',
      level: '0',
    },
    {
      fdis: 'F34',
      level: '0',
    },
  ],
  fbCheckList: [],
  service: [
    {
      svc: 'V01',
      need: '1',
    },
    {
      svc: 'V02',
      need: '1',
    },
    {
      svc: 'V03',
      need: '1',
    },
    {
      svc: 'V04',
      need: '1',
    },
    {
      svc: 'V05',
      need: '1',
    },
    {
      svc: 'V06',
      need: '1',
    },
    {
      svc: 'V07',
      need: '1',
    },
    {
      svc: 'V08',
      need: '1',
    },
    {
      svc: 'V09',
      need: '1',
    },
    {
      svc: 'V10',
      need: '1',
    },
    {
      svc: 'V11',
      need: '1',
    },
    {
      svc: 'V12',
      need: '1',
    },
    {
      svc: 'V13',
      need: '1',
    },
    {
      svc: 'V14',
      need: '1',
    },
    {
      svc: 'V15',
      need: '1',
    },
    {
      svc: 'V16',
      need: '1',
    },
    {
      svc: 'V17',
      need: '1',
    },
  ],
  serviceCheckList: [],
}

export const formData_bj_default = {
  mobile: '',
  yyuserid: '',
  sex: '',
  birth: '',
  examdate: '',
  corp: '',
  goods_id: '',
  height: '',
  weight: '',
  waist: '',
  hip: '',
  fat: '',
  area: '',
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    { kdis: 'K01', level: '0' },
    { kdis: 'K02', level: '0' },
    { kdis: 'K03', level: '0' },
    { kdis: 'K04', level: '0' },
    { kdis: 'K05', level: '0' },
    { kdis: 'K06', level: '0' },
    { kdis: 'K07', level: '0' },
    { kdis: 'K08', level: '0' },
    { kdis: 'K09', level: '0' },
    { kdis: 'K10', level: '0' },
    { kdis: 'K11', level: '0' },
    { kdis: 'K12', level: '0' },
    { kdis: 'K13', level: '0' },
    { kdis: 'K14', level: '0' },
    { kdis: 'K15', level: '0' },
    { kdis: 'K16', level: '0' },
    { kdis: 'K17', level: '0' },
    { kdis: 'K18', level: '0' },
    { kdis: 'K19', level: '0' },
  ],
  oper: [],
  famdis: [],
  examine: [
    { ne: 'WBC', ve: '', ut: 'A' },
    { ne: 'HGB', ve: '', ut: 'A' },
    { ne: 'PLT', ve: '', ut: 'A' },
    { ne: 'FG', ve: '', ut: 'B' },
    { ne: 'HBA1C', ve: '', ut: 'A' },
    { ne: 'CHOL', ve: '', ut: 'B' },
    { ne: 'LDLC', ve: '', ut: 'B' },
    { ne: 'HDLC', ve: '', ut: 'B' },
    { ne: 'TG', ve: '', ut: 'B' },
    { ne: 'GOT', ve: '', ut: 'A' },
    { ne: 'GPT', ve: '', ut: 'A' },
    { ne: 'ALP', ve: '', ut: 'A' },
    { ne: 'ALB', ve: '', ut: 'B' },
    { ne: 'CRE', ve: '', ut: 'B' },
    { ne: 'UA', ve: '', ut: 'B' },
    { ne: 'EGFR', ve: '', ut: 'A' },
  ],
}
export const formData_m22_default = {
  mobile: '',
  yyuserid: '',
  sex: '',
  birth: '',
  examdate: '',
  corp: '',
  goods_id: '',
  height: '',
  weight: '',
  waist: '',
  hip: '',
  fat: '',
  examine: [
    { ne: 'FG', ve: '', ut: 'B' },
    { ne: 'HBA1C', ve: '', ut: 'A' },
    { ne: 'CHOL', ve: '', ut: 'B' },
    { ne: 'LDLC', ve: '', ut: 'B' },
    { ne: 'HDLC', ve: '', ut: 'B' },
    { ne: 'TG', ve: '', ut: 'B' },
    { ne: 'UA', ve: '', ut: 'B' },
  ],
}
export const formData_zhihui_default = {
  mobile: '',
  yyuserid: '',
  sex: '',
  birth: '',
  corp: '',
  goods_id: '',
  height: '',
  weight: '',
  waist: '',
  hip: '',
  fat: '',
  area: '',
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    { kdis: 'K01', level: '0' },
    { kdis: 'K02', level: '0' },
    { kdis: 'K03', level: '0' },
    { kdis: 'K04', level: '0' },
    { kdis: 'K05', level: '0' },
    { kdis: 'K06', level: '0' },
    { kdis: 'K07', level: '0' },
    { kdis: 'K08', level: '0' },
    { kdis: 'K09', level: '0' },
    { kdis: 'K10', level: '0' },
    { kdis: 'K11', level: '0' },
    { kdis: 'K12', level: '0' },
    { kdis: 'K13', level: '0' },
    { kdis: 'K14', level: '0' },
    { kdis: 'K15', level: '0' },
    { kdis: 'K16', level: '0' },
    { kdis: 'K17', level: '0' },
    { kdis: 'K18', level: '0' },
    { kdis: 'K19', level: '0' },
  ],
  oper: [],
  famdis: [],
}

export const formData_mb_default = {
  mobile: '',
  yyuserid: '',
  corp: '',
  sex: 2,
  goods_id: '',
  examdate: '',
  birth: '',
  height: '',
  weight: '',
  waist: '',
  hip: '',
  fat: '',
  area: '',
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    {
      kdis: 'K01',
      level: '0',
    },
    {
      kdis: 'K02',
      level: '0',
    },
    {
      kdis: 'K03',
      level: '0',
    },
    {
      kdis: 'K04',
      level: '0',
    },
    {
      kdis: 'K05',
      level: '0',
    },
    {
      kdis: 'K06',
      level: '0',
    },
    {
      kdis: 'K07',
      level: '0',
    },
    {
      kdis: 'K08',
      level: '0',
    },
    {
      kdis: 'K09',
      level: '0',
    },
    {
      kdis: 'K10',
      level: '0',
    },
    {
      kdis: 'K11',
      level: '0',
    },
    {
      kdis: 'K12',
      level: '0',
    },
    {
      kdis: 'K13',
      level: '0',
    },
    {
      kdis: 'K14',
      level: '0',
    },
    {
      kdis: 'K15',
      level: '0',
    },
    {
      kdis: 'K16',
      level: '0',
    },
    {
      kdis: 'K17',
      level: '0',
    },
    {
      kdis: 'K18',
      level: '0',
    },
    {
      kdis: 'K19',
      level: '0',
    },
  ],
  oper: [],
  famdis: [],
  examine: [
    {
      ne: 'WBC',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'HGB',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'PLT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'FG',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'HBA1C',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'CHOL',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'LDLC',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'HDLC',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'TG',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'GOT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'GPT',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'ALP',
      ve: '',
      ut: 'A',
    },
    {
      ne: 'ALB',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'CRE',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'UA',
      ve: '',
      ut: 'B',
    },
    {
      ne: 'EGFR',
      ve: '3',
      ut: 'A',
    },
  ],
  preweek: '',
  preweight: '',
  prefat: '',
  prebust: '',
  prewaist: '',
  prehip: '',
  mage: '',
  bsex: '',
  week: '',
  bweight: '',
  aps1: '',

  predis2: [],
  yyuserid: '',
  mage: '',
  bsex: '',
  week: '',
  bweight: '',
  aps1: '',
  preweek: '',
  preweight: '',
  prefat: '',
  prebust: '',
  prewaist: '',
  prehip: '',
  goods_type: '',
}
