<template>
  <div class="bodily">
    <!-- <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">产前的体型信息</h3> -->

    <el-form :model="formData" :rules="rules" inline ref="bodily">

      <el-row :gutter="16">
        <el-col :span="24">
          <div class="insert-title">产前的体型信息</div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身高:" prop="height">
            <el-input v-model="formData.height" placeholder="请输入">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="体重:" prop="weight">
            <el-input v-model="formData.weight" placeholder="请输入">
              <template slot="append">kg</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="腰围:" prop="waist">
            <el-input v-model="formData.waist" placeholder="请输入">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">

          <el-form-item label="臀围:" prop="hip">
            <el-input v-model="formData.hip" placeholder="请输入">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="体脂率:" prop="fat">
            <el-input v-model="formData.fat" placeholder="请输入(选填)">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="insert-title margin-top-40">产后的体型信息</div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="孕周数:" prop="preweek">
            <el-input v-model="formData.preweek" placeholder="请输入">
              <template slot="append">周</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="体重:" prop="preweight">
            <el-input v-model="formData.preweight" placeholder="请输入">
              <template slot="append">kg</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="腰围:" prop="prewaist">
            <el-input v-model="formData.prewaist" placeholder="请输入">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="臀围:" prop="prehip">
            <el-input v-model="formData.prehip" placeholder="请输入">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="体脂率:" prop="prefat">
            <el-input v-model="formData.prefat" placeholder="请输入(选填)">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-block">下一步</el-button>
    </div>
  </div>
</template>

<script>
let numberInput = function (value) {
  var price = '' + value;
  price = price
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  if (price.indexOf('.') < 0 && price != '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    price = parseFloat(price);
  }
  return price
}
export default {
  name: "bodily",
  data () {
    var heightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 130 || numberInput(value) > 200) {
        callback(new Error('身高为130~200'));
      } else {
        callback();
      }
    }
    var weightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 30 || numberInput(value) > 160) {
        callback(new Error('体重为30~160'));
      } else {
        callback();
      }
    }

    var waistVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150) {
        callback(new Error('腰围为40~150'));
      } else {
        callback();
      }
    }
    var hipVerify = (rule, value, callback) => {
      if (value != '') {
        if (isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165) {
          callback(new Error('臀围为60~165'));
        } else {
          callback();
        }
      }
      if (value == '') {
        callback();
      }

    }
    var fatVerify = (rule, value, callback) => {
      if (value != '') {
        if (isNaN(numberInput(value)) || numberInput(value) && numberInput(value) < 3 || numberInput(
          value) > 75) {
          callback(new Error('体脂率为3~75'));
        } else {
          callback();
        }
      }
      if (value == '') {
        callback();
      }
    }

    return {
      rules: {
        // 产前
        height: [{
          required: true,
          message: '请填写您的身高',
          trigger: 'blur'
        },
        {
          validator: heightVerify,
          trigger: 'blur'
        }
        ],
        weight: [{
          required: true,
          message: '请填写您的体重',
          trigger: 'blur'
        },
        {
          validator: weightVerify,
          trigger: 'blur'
        }
        ],
        // bust: [
        // 	{ required: true, message: '请填写您的胸围', trigger: 'blur' },
        // 	{ validator: bustVerify, trigger: 'blur' }
        // ],
        waist: [{
          required: true,
          message: '请填写您的腰围',
          trigger: 'blur'
        },
        {
          validator: waistVerify,
          trigger: 'blur'
        }
        ],
        hip: [{
          required: false,
          message: '请填写您的臀围',
          trigger: 'blur'
        },
        {
          validator: hipVerify,
          trigger: 'blur'
        }
        ],
        fat: [{
          validator: fatVerify,
          trigger: 'blur'
        }],

        // 产后
        preweek: [{
          required: true,
          message: '请填写您的孕周数',
          trigger: 'blur'
        }],
        preweight: [{
          required: true,
          message: '请填写您的体重',
          trigger: 'blur'
        },
        {
          validator: weightVerify,
          trigger: 'blur'
        }
        ],

        prewaist: [{
          required: true,
          message: '请填写您的腰围',
          trigger: 'blur'
        },
        {
          validator: waistVerify,
          trigger: 'blur'
        }
        ],
        prehip: [{
          required: false,
          message: '请填写您的臀围',
          trigger: 'blur'
        },
        {
          validator: hipVerify,
          trigger: 'blur'
        }
        ],
        prefat: [{
          validator: fatVerify,
          trigger: 'blur'
        }]
      },

    }
  },
  props: ['formData'],
  methods: {
    handlePrev () {
      this.$emit('prevStep');
    },
    //下一步
    next () {
      this.$refs.bodily.validate((isSuc, err) => {
        if (isSuc) {
          this.$emit('nextStep');
        }
      });
    },
  }
}
</script>

<style scoped>
.bodily>>>.el-form-item__label {
  width: 100%;
  text-align: left;
}



.bodily>>>.el-input {
  width: 100%;
}

.bodily>>>.el-input input {
  width: 100%;
}

.el-row>>>.el-form-item__content {
  width: 100%;
}

.el-row>>>.el-input,
.el-select {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.bodily {
  padding-top: 50px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form-item {
    width: 100%;


  }



  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }

  .el-form {
    h3 {
      margin-top: 40px;
    }

    .el-form-item {
      width: 100%;
      margin: 24px 0 0 0;

      &.qjc-wid-100 {
        width: 100%;
      }
    }
  }

}

.margin-top-40 {
  margin-top: 40px;
}
</style>