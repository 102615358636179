<template>
  <div class="biochemical">

    <el-form class="qjc-clearfix">
      <el-row :gutter="16">
        <el-col :span="24">
          <div class="form-header-title">{{ $t('open.t71') }}({{ $t('open.t72') }})</div>
        </el-col>
        <el-col :span="24">
          <div class="insert-title qjc-wid-100">{{ $t('open.t38') }}</div>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t47')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[0].ve" @input="mustNumber(0)" :placeholder="$t('goods.t16')"
              @blur="inputRange(0, 30, 700, 1.5, 40)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[0].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">mmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t48')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[1].ve" @input="mustNumber(1)" :placeholder="$t('goods.t16')"
              @blur="inputRange(1, 1, 25, 0, 47.5)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[1].ut">
                  <el-radio label="A">%</el-radio>
                  <el-radio label="B">mmol/mol</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="insert-title qjc-wid-100">{{ $t('open.t39') }}</div>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t49')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[2].ve" @input="mustNumber(2)" :placeholder="$t('goods.t16')"
              @blur="inputRange(2, 50, 1100, 1.2, 28.5)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[2].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">mmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t50')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[3].ve" @input="mustNumber(3)" :placeholder="$t('goods.t16')"
              @blur="inputRange(3, 1, 650, 0, 17)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[3].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">mmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t51')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[4].ve" @input="mustNumber(4)" :placeholder="$t('goods.t16')"
              @blur="inputRange(4, 1, 250, 0, 7)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[4].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">mmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t52')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[5].ve" @input="mustNumber(5)" :placeholder="$t('goods.t16')"
              @blur="inputRange(5, 1, 9000, 0, 110)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[5].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">mmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="insert-title qjc-wid-100">{{ $t('open.t41') }}</div>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('open.t58')" class="qjc-fl qjc-wid-50">
            <el-input v-model="formData.examine[6].ve" @input="mustNumber(6)" :placeholder="$t('goods.t16')"
              @blur="inputRange(6, 0, 20, 0, 1200)">
              <div class="suffix qjc-fts-14" slot="suffix">
                <el-radio-group v-model="formData.examine[6].ut">
                  <el-radio label="A">mg/dL</el-radio>
                  <el-radio label="B">μmol/L</el-radio>
                </el-radio-group>
              </div>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
      <div class="footer-btn">
        <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{ $t('open.t12') }}</el-button>
        <el-button @click="handleSubmit" :loading="submiting" class="next qjc-fts-16 qjc-block">{{ $t('open.t61')
          }}</el-button>
      </div>

      <el-dialog title="提示" :visible.sync="updateShow" width="30%" :before-close="handleUpdateClose">
        <span>每份套餐的修改次数最多三次，请您确认修改内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancel-btn" @click="handleUpdateClose">取 消</el-button>
          <el-button class="submit-btn" type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import {
  packageOpen
} from '@/utils/axios'
import {
  Base64
} from 'js-base64'

export default {
  name: 'biochemical',
  data () {
    return {
      submiting: false,
      updateShow: false, //修改确认弹窗
      isSuper: false,
      examineArry: [{
        "ne": "FG",
        "ve": "",
        "ut": "B"
      },
      {
        "ne": "HBA1C",
        "ve": "",
        "ut": "A"
      },
      {
        "ne": "CHOL",
        "ve": "",
        "ut": "B"
      },
      {
        "ne": "LDLC",
        "ve": "",
        "ut": "B"
      },
      {
        "ne": "HDLC",
        "ve": "",
        "ut": "B"
      },
      {
        "ne": "TG",
        "ve": "",
        "ut": "B"
      },
      {
        "ne": "UA",
        "ve": "",
        "ut": "B"
      },
      ]
    }
  },
  props: ['type', 'formData'],
  inject: ['handleRefresh'],
  mounted () {
    let isSuper = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))
      .issupper : '0';
    this.isSuper = isSuper


    this.formData.examine.map((item, index) => {
      if (item.ve != '') {
        this.examineArry[index].ve = item.ve
        this.examineArry[index].ut = item.ut
      }
    })
    this.formData.examine = this.examineArry

  },
  methods: {
    handleUpdateClose () {
      this.updateShow = false;
    },
    handleSubmit () {

      if ((this.type == 'update' && this.isSuper != '1')) {
        this.updateShow = true;
      } else {
        this.submit()
      }
    },
    // 确认提交
    submit () {
      // 添加操作人
      this.formData.operator = localStorage.getItem('mobile');
      if (!this.verifyNull()) {
        //是否存在未填写信息
        // 可为空data(二选一，后面再加验证)
        var white = ['yyuserid', 'mobile'];
        for (var key in this.formData) {
          if (white.indexOf(key) == -1) {
            if (!key) {
              this.$myAlert({
                type: 'fail',
                content: '存在未填写的信息'
              });
              return false;
            }
          }
        }
        //手机号与企业id至少一项需填写
        if (!this.formData.yyuserid && !this.formData.mobile) {
          this.$myAlert({
            type: 'fail',
            content: '手机号与企业用户ID至少填写一项'
          });
          return false;
        }

        const formData = JSON.parse(JSON.stringify(this.formData))

        this.submiting = true;


        if (this.type == 'update') {
          formData.act = 'update'
        }
        if (formData.examdate == '') {
          formData.examdate = Date.parse(new Date()) / 1000
        }
        this.submiting = true;
        // return;
        formData.birth = formData.birth.split('/').join('')

        delete formData.area
        delete formData.drink
        delete formData.smoke
        delete formData.habeat
        delete formData.vege
        delete formData.habsport
        delete formData.sleep
        delete formData.disease
        delete formData.oper
        delete formData.famdis
        delete formData.fat
        delete formData.package
        delete formData.type
        delete formData.sbp
        delete formData.dbp
        delete formData.chest
        delete formData.prehip
        delete formData.sbpCombo


        formData.examine.forEach(item => {
          if (!item.ve) {
            item.ut = 'A'
          }
        })

        if (formData.examdate) {
          formData.examdate = formData.examdate.split('/').join('')

        }

        const goods_type = formData.goods_type
        delete formData.goods_type

        console.log("formData", formData);

        packageOpen(formData).then(res => {

          this.submiting = false;

          if (res.code == 200) {
            this.updateShow = false;
            const {
              id
            } = JSON.parse(Base64.decode(res.data))
            this.download(goods_type, id)
            this.$emit('resetFormData');

          } else {
            this.updateShow = false;
            if (this.formData.birth.split('/').length <= 1) {
              let newArr = this.formData.birth.split('')
              newArr.splice(4, 0, '/')
              newArr.splice(-2, 0, '/')
              this.formData.birth = newArr.join('')
            }
            this.$myAlert({
              type: 'fail',
              content: res.msg
            });
          }
        });
      }

    },
    download (type, id) {
      if (type == 'AIMB') { // 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });
        window.open(openUrl.href);
      }

    },
    //验证不为空
    verifyNull () {
      let examineList = [{
        "ne": "FG",
        "ve": "",
        "ut": "B",
        "name": "空腹血糖(FG)",
        "en_name": "Fasting Glucose(FG)"
      },
      {
        "ne": "HBA1C",
        "ve": "",
        "ut": "A",
        "name": "糖化血红蛋白(HBA1C)",
        "en_name": "Glycated hemoglobin (HBA1C)"
      },
      {
        "ne": "CHOL",
        "ve": "",
        "ut": "B",
        "name": "总胆固醇(CHOL)",
        "en_name": "Total Cholesterol (CHOL)"
      },
      {
        "ne": "LDLC",
        "ve": "",
        "ut": "B",
        "name": "低密度脂蛋白(LDLC)",
        "en_name": "Low-density lipoprotein (LDLC)"
      },
      {
        "ne": "HDLC",
        "ve": "",
        "ut": "B",
        "name": "高密度脂蛋白(HDLC)",
        "en_name": "High-density lipoprotein (HDL)"
      },
      {
        "ne": "TG",
        "ve": "",
        "ut": "B",
        "name": "甘油三酯(TG)",
        "en_name": "Triglycerides (TG)"
      },
      {
        "ne": "UA",
        "ve": "",
        "ut": "B",
        "name": "尿酸(UA)",
        "en_name": "Uric acid (UA)"
      },
      ];

      let lang = sessionStorage.getItem('theLanguage') ? sessionStorage.getItem('theLanguage') : 'zh'
      /* for (var i = 0; i < this.formData.examine.length; i++) {
        if (this.formData.examine[i].ve == '') { // i=15(肾小球过滤率)暂时可为空
          this.$myAlert({
            title: this.$t('open.f46'),
            content: this.$t('goods.t16') + '  ' + (lang == 'zh' ? examineList[i].name :
              examineList[i].en_name)
          });
          return true;
        }
      } */

      const arr = this.formData.examine.filter((item) => item.ve)

      if (arr.length) {

        return false
      } else {
        this.$myAlert({
          title: this.$t('open.f46'),
          content: this.$t('goods.t16') + '任意一项'
        });
        return true
      }



    },

    //必须输入数字 参数对应examine下标
    mustNumber (index) {
      this.formData.examine[index].ve = this.formData.examine[index].ve.match(/\d/g) ? this.formData.examine[
        index].ve.match(/[\d || .]/g).join('') : '';
    },

    //输入偏差提示 参数1.data中examine下标  2.最小值(存在单位切换时为A单位最小值)  3.最大值(存在单位切换时为A单位最大值)  4.B最小值(存在单位切换时为B单位最小值) 5.B最大值(存在单位切换时为B单位最小值)
    inputRange (index, min, max, min2, max2) {

      if (this.formData.examine[index].ve != '') {
        if (min2 || max2 && this.formData.examine[index].ut == 'B') { //存在单位切换且为单位为B值时
          if (this.formData.examine[index].ve < min2 || this.formData.examine[index].ve > max2) {
            this.$myAlert({
              title: this.$t('open.f60'),
              content: this.$t('open.f61')
            });
          }
        } else {
          if (this.formData.examine[index].ve < min || this.formData.examine[index].ve > max) {
            this.$myAlert({
              title: this.$t('open.f60'),
              content: this.$t('open.f61')
            });
          }
        }
      }

    },

    handlePrev () {
      this.$emit('prevStep');
    },
  }
}
</script>

<style scoped>
.biochemical>>>.el-form-item__label {
  width: 100%;
  text-align: left;
}



.biochemical>>>.el-radio__label {}
</style>
<style lang="scss" scoped>
.biochemical {
  padding-top: 32px;

  .form-cell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 23px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    margin-top: 22px;

    .el-form-item {
      width: 100%;

      margin-bottom: 24px;

      .el-input {
        width: 100%;

        .el-radio {
          font-weight: normal;
          color: #777F8F;
        }

        .suffix {
          color: #777F8F;
          margin-right: 16px;

          sub {
            display: inline-block;
            vertical-align: baseline;
            font-size: inherit;
            transform: translateY(9%)scale(.65);
          }

          sup {
            display: inline-block;
            vertical-align: top;
            font-size: inherit;
            transform: translateY(-9%)scale(.65);
          }
        }
      }

      .submit {
        width: 200px;
        height: 48px;
        margin-top: 40px;
      }
    }
  }

}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.next {
  width: 96px;
  height: 34px;
  border-radius: 17px;
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}

.prev {
  width: 96px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #6883FB;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6883FB;
  background-color: #fff;
  margin-right: 24px;
}


.cancel-btn {
  width: 80px;
  height: 40px;
  color: #606266;
  background-color: #fff;
}

.submit-btn {
  width: 80px;
  height: 40px;
  background-color: #6681FA;
  border: none;
}
</style>