<template>
  <div class="personal">

    <el-form :model="formData" :rules="rules" inline :hide-required-asterisk="true" ref="personal">

      <div class="insert-title">{{ $t('open.t1') }}</div>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item prop="mobile" label-width="100%">
            <div class="label qjc-fts-14" slot="label">
              {{ `${$t('open.t5')}(${$t('open.f27')})` }}
            </div>
            <div class="open-item-tip" v-show="isShowPhone">{{ formData.mobile }}</div>
            <el-input :disabled="type == 'update'" v-model.trim="formData.mobile" type="text"
              :placeholder="$t('open.f25')" @input="phoneMustNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="yyuserid" label-width="100%">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t6') }}({{ $t('open.f27') }})
            </div>
            <el-input :disabled="type == 'update'" v-model.trim="formData.yyuserid" type="text" :change="check_num()"
              :placeholder="$t('open.f26')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="form-input-box">
          <el-form-item prop="sex" label-width="100%">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t7') }}
            </div>
            <el-select v-model="formData.sex" :placeholder="$t('open.f28')">
              <el-option :label="$t('open.t14')" :value="1"></el-option>
              <el-option :label="$t('open.t15')" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="form-input-box">
          <el-form-item prop="birth" label-width="100%">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t8') }}
            </div>
            <el-date-picker v-model="birthSel" type="date" :placeholder="$t('open.f28')" value-format="yyyy/MM/dd"
              format="yyyy/MM/dd" @change="handleBirth" :default-value="new Date('1990/1/1')">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="form-input-box">
          <el-form-item prop="corp" label-width="100%">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t9') }}
            </div>
            <el-input v-model.trim="formData.corp" :placeholder="$t('open.f47')" type="text"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="insert-title">{{ $t('open.t3') }}</div>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label-width="80px" prop="height">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t30') }}
            </div>
            <el-input v-model.trim="formData.height" :placeholder="$t('open.f41')">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="weight">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t31') }}
            </div>
            <el-input v-model.trim="formData.weight" :placeholder="$t('open.f42')">
              <template slot="append">kg</template>
            </el-input>

          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label-width="80px" prop="waist">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t32') }}
            </div>
            <el-input v-model.trim="formData.waist" :placeholder="$t('open.f43')">
              <template slot="append">cm</template></el-input>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="hip">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t33') }}
            </div>
            <el-input v-model.trim="formData.hip" :placeholder="$t('open.f44')">
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="fat">
            <div class="label qjc-fts-14" slot="label">
              {{ $t('open.t34') }}
            </div>
            <el-input v-model.trim="formData.fat" :placeholder="$t('open.t35')">
              <template slot="append">%</template>
            </el-input>

          </el-form-item>
        </el-col>
      </el-row>
      <div class="footer-btn">
        <el-button @click="resetFormData" class="prev qjc-fts-16 qjc-block">{{ $t('open.resetForm') }}</el-button>

        <el-button @click="next" class="next qjc-fts-16 qjc-block">{{ $t('open.t13') }}</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import {
  isPhone
} from '@/utils'


let numberInput = function (value) {
  var price = '' + value;
  price = price
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  if (price.indexOf('.') < 0 && price != '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    price = parseFloat(price);
  }
  return price
}


export default {
  name: 'PersonalZhihui',
  data () {
    // 验证
    // 手机号
    var mobileVerify = (rule, value, callback) => {
      if (this.formData.yyuserid) {
        if (value == '') {
          callback();
        } else if (isPhone(value)) {
          callback();
        } else {
          callback(new Error('手机号格式错误'));
        }
      } else {
        if (value == '') {
          callback(new Error(this.$t('open.f27')));
        } else if (isPhone(value)) {
          callback();
        } else {
          callback(new Error('手机号格式错误'));
        }
      }
    }

    // 企业用户id验证
    var yyuseridVerify = (rule, value, callback) => {
      if (this.formData.mobile) {
        callback();
      } else {
        if (value == '') {
          callback(new Error(this.$t('open.f27')));
        } else {
          callback();
        }
      }
    }

    // 出生日期验证
    var birthVerify = (rule, value, callback) => {
      let minNum = 20;
      let maxNum = 90;

      if (this.birthSel == '') {
        callback(new Error(this.$t('open.f29')));
      }
      if (this.getAgeBirth(new Date(this.birthSel).getTime()) >= 20 && this.getAgeBirth(new Date(this
        .birthSel).getTime()) <= 90) {
        callback();
      } else {
        callback(new Error(this.$t('open.a1')));
      }

    }

    // 体检日期验证
    var examdateVerify = (rule, value, callback) => {
      if (this.birthSel == '') {
        callback(new Error(this.$t('open.f29')));
      }
      if (this.examdateSel == '') {
        callback(new Error(this.$t('open.f30')));
      } else {
        let nowDate = new Date();
        let nowTime = new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate
          .getDate()).getTime(); // 当前日期时间戳
        let birthTime = new Date(this.birthSel).getTime()
        if (nowTime >= new Date(this.examdateSel).getTime() && birthTime < new Date(this.examdateSel)
          .getTime()) {
          callback();
        } else {
          callback(new Error(this.$t('open.f31')));
        }
      }
      if (this.goodsType == 'AIZH' || this.goodsType == 'AIFB') {
        callback();
      }


    }

    // 基础套餐必选
    var examBasicVerify = (rule, value, callback) => {
      callback()

    }


    var heightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 130 || numberInput(value) > 200) {
        callback(new Error('身高为130~200'));
        // callback(new Error(this.$t('open.t54')));
      } else {
        callback();
      }
    }
    var weightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 30 || numberInput(value) > 160) {
        callback(new Error('体重为30~160'));
        // callback(new Error(this.$t('open.t55')));
      } else {
        callback();
      }
    }
    var chestVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 165) {
        callback(new Error(this.$t('open.t56')));
      } else {
        callback();
      }
    }
    var waistVerify = (rule, value, callback) => {
      if (this.hasFb) {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150) {
            callback(new Error('腰围为40~150'));
            // callback(new Error(this.$t('open.t57')));
          } else {
            callback();
          }
        } else {
          callback(new Error('请输入腰围'));
          // callback(new Error(this.$t('open.t50')));
        }
      } else {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150) {
            callback(new Error('腰围40~150'));
            // callback(new Error(this.$t('open.t57')));
          } else {
            callback();
          }
        }
        if (value == '') {
          callback();
        }
      }
    }
    var hipVerify = (rule, value, callback) => {
      if (this.hasFb) {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165) {
            callback(new Error('臀围为60~165'));
          } else {
            callback();
          }
        } else {
          callback(new Error('请输入臀围'));
        }
      } else {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165) {
            callback(new Error('臀围为60~165'));
          } else {
            callback();
          }
        }
        if (value == '') {
          callback();
        }
      }
    }
    var fatVerify = (rule, value, callback) => {
      if (value != '') {
        if (isNaN(numberInput(value)) || numberInput(value) && numberInput(value) < 3 || numberInput(
          value) > 75) {
          // callback(new Error(this.$t('open.t59')));
          callback(new Error('体脂率3~75'));
        } else {
          callback();
        }
      }
      if (value == '') {
        callback();
      }
    }

    return {
      mobileConfirm: '', // 手机号确认
      mobileConfirmErr: false, // 手机号确认错误消息显示

      yyuseridConfirm: '', // 企业用户id确认
      yyuseridConfirmErr: false, // 手机号确认错误消息显示

      rules: {
        mobile: [{
          validator: mobileVerify,
          trigger: 'blur'
        }],
        yyuserid: [{
          validator: yyuseridVerify,
          trigger: 'blur'
        }],
        // 性别:1男  2女
        sex: [{
          required: true,
          message: this.$t('open.f49'),
          trigger: 'change'
        }],
        birth: [{
          required: true,
          message: this.$t('open.f29'),
          trigger: 'blur'
        },
        {
          validator: birthVerify,
          trigger: 'change'
        }
        ],
        examdate: [{
          required: true,
          message: this.$t('open.f30'),
          trigger: 'blur'
        },
        {
          validator: examdateVerify,
          trigger: 'change'
        }
        ],
        examplace: [{
          required: false,
          message: '请输入体检地点',
          trigger: 'blur'
        }],
        height: [{
          required: true,
          message: this.$t('open.f39'),
          trigger: 'blur'
        },
        {
          validator: heightVerify,
          trigger: 'blur'
        }
        ],
        weight: [{
          required: true,
          message: this.$t('open.f40'),
          trigger: 'blur'
        },
        {
          validator: weightVerify,
          trigger: 'blur'
        }
        ],
        chest: [{
          required: true,
          message: this.$t('open.f53'),
          trigger: 'blur'
        },
        {
          validator: chestVerify,
          trigger: 'blur'
        }
        ],
        waist: [{
          required: false,
          message: this.$t('open.f50'),
          trigger: 'blur'
        },
        {
          validator: waistVerify,
          trigger: 'blur'
        }
        ],
        hip: [{
          required: false,
          message: this.$t('open.f51'),
          trigger: 'blur'
        },
        {
          validator: hipVerify,
          trigger: 'blur'
        }
        ],
        fat: [
          // { required: true, message: '请填写您的体脂率', trigger: 'blur' } ,
          {
            validator: fatVerify,
            trigger: 'blur'
          }
        ]

      },

      count: {
        base: {
          times: 0 //默认为0 
        },
        packageA: {
          times: 0 //默认为0
        },
        packageB: {
          times: 0 //默认为0
        },
        packageC: {
          times: 0 //默认为0
        },
        packageD: {
          times: 0 //默认为0
        }
      }, //套餐剩余次数
      packageList: [],
      package_opt: '1',
      pay_mode: '1',
      // 体检日期不能选择今日以前
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      isShowPhone: false,
      companyList: [],
      packageValue: '',
      checkCustomList: [],
      corpname: '',
      isSuper: 0,
      goodsList: [],
      pay_mode: '',
      goodsType: '',
    }
  },
  computed: {
    birthSel: { // 出生日期展示
      get () {
        if (this.formData.birth) { //不能为空
          // 时间戳妙转为毫秒
          return this.formData.birth;
        } else {
          return '';
        }

      },
      set (value) {
        // 毫秒转换为秒
        this.formData.birth = value;

      }
    },
    examdateSel: { // 体检日期展示
      get () {
        if (this.formData.examdate) { //不能为空
          // 时间戳妙转为毫秒
          return this.formData.examdate;
        } else {
          return "";
        }
      },
      set (value) {
        // 毫秒转换为秒
        this.formData.examdate = value;
      }
    }
  },
  props: ['type', 'formData', 'step'],
  inject: ['handleRefresh'],
  mounted () {

    console.log("Person-zhihui mounted");
    let isSuper = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))
      .issupper : ''
    this.isSuper = isSuper
    setTimeout(() => {
      console.log(this.formData);
      if (this.formData.goods_type) {
        this.goodsType = this.formData.goods_type
      } else {

      }
      if (this.formData.goods_id) {
        this.packageValue = this.formData.goods_id
      } else {

      }
      if (this.formData.sex != '') {
        this.formData.sex = Number(this.formData.sex)
      }
    }, 500)
  },
  watch: {

  },
  methods: {
    handlePackage (goods) {
      console.log("goods", goods.type, goods.goods_id);
      this.formData.goods_type = goods.type
      this.goodsType = goods.type
      this.formData.goods_id = goods.goods_id
      this.formData.area = ""
      this.formData.package = goods.goods_id
    },
    getAge (birthday, examdate) {
      let num = ((864000000 / 2) - 86400000)
      return Math.ceil(((examdate - birthday) - num) / 31536000000) - 1;
    },
    handleBirth (val) {
    },
    handleExamdate (val) {
    },
    getAgeBirth (birthday) {
      let num = this.goodsType == 'AIFB' ? 864000000 : (864000000 / 2)
      let nowTime = new Date().getTime()
      return Math.ceil(((nowTime - birthday) - num) / 31536000000) - 1;
    },
    check_num () {
      if (this.formData.yyuserid) {
        this.formData.yyuserid = this.formData.yyuserid.replace(/[^\a-\z\A-\Z0-9]/g, '');
      }
    },
    // 下一步
    next () {

      console.log("validate and next");

      // 报告编码和输入框一致

      this.$refs.personal.validate((isSuc, err) => {
        // 验证通过且确认输入也通过
        if (isSuc) {
          console.log("next");
          // 成功进入下一步
          this.$emit('nextStep')

        }
      });

    },

    // 手机号确认
    mobileConfirmVerify () {
      if (this.mobileConfirm != this.formData.mobile) {
        this.mobileConfirmErr = true;
        return false;
      } else {
        this.mobileConfirmErr = false;
        return true;
      }
    },

    // 企业用户id确认
    yyuseridConfirmVerify () {
      if (this.yyuseridConfirm != this.formData.yyuserid) {
        this.yyuseridConfirmErr = true;
        return false;
      } else {
        this.yyuseridConfirmErr = false;
        return true;
      }
    },

    // 距今year年的时间戳 参数:year:距今年份
    limitTime (year) {
      let nowDate = new Date();
      return new Date((nowDate.getFullYear() - year) + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate())
        .getTime();
    },

    // 手机号必须输入数字
    phoneMustNumber (value) {
      this.formData.mobile = value.replace(/\D/g, '');
      this.isShowPhone = true
      setTimeout(() => {
        this.isShowPhone = false
      }, 5000)
    },
    phoneConfirmMustNumber (value) {
      this.mobileConfirm = value.replace(/\D/g, '');
    },
    resetFormData () {
      this.$emit("resetFormData")
    }
  }
}
</script>

<style scoped>
.el-form>>>.el-form-item__label {
  line-height: 36px;
}

.el-form .el-input>>>input,
.el-form>>>.el-input__inner {
  height: 36px;
  line-height: 16px;
  font-size: 14px;
}

.el-form>>>.el-form-item__content,
.el-form>>>.el-input__icon {
  line-height: 36px;
}





.el-form>>>.el-checkbox__label {
  font-size: 14px;
}

.wid-100>>>.el-form-item__content {
  flex: 1;
}

.el-form>>>.is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #6883FB;
}

.el-form>>>.is-checked .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #6681FA;
  background-color: #6681FA;
}

.el-form>>>.el-radio.is-bordered.is-checked {
  border-color: #6681FA
}

.el-form>>>.is-checked .el-radio__input.is-disabled+span.el-radio__label {
  color: #6681FA;
}

.el-form>>>.is-checked .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #6681FA;
}

.goods-type>>>.el-form-item__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;


}





.goods-type>>>.el-form-item__content::before {
  content: none;
  display: none;
}


.el-row>>>.el-form-item__content {
  width: 100%;
}

.el-row>>>.el-input,
.el-select {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.el-form-item__content {
  .package {
    margin-right: 20px;
  }
}

.goods-type {
  display: block;
  width: 100% !important;
}


.personal {
  padding-top: 50px;

  .open-item-tip {
    position: absolute;
    top: -40px;
    left: 0px;
    width: 438px;
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px 0px #D7E0ED;
    border-radius: 2px;
  }

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 4px;
    margin-top: 20px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    .el-form-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;


      .label {
        display: flex;
        justify-content: flex-start;
        color: #777F8F;
      }



      .tip {
        line-height: 12px;
        margin-top: 6px;
        color: #ACAEB3;
      }



      p.base-count {
        width: 193px;
        margin-bottom: 16px;
      }

      .count {
        font-size: 13px;
        line-height: 13px;
        color: #777F8F;
        margin-top: 6px;
      }
    }


    h3 {
      margin: 40px 0 24px;
    }

    .footer-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }

    .next {
      width: 96px;
      height: 34px;
      border-radius: 17px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #fff;
    }

    .prev {
      width: 96px;
      height: 34px;
      border-radius: 17px;
      border: 1px solid #6883FB;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6883FB;
      background-color: #fff;
      margin-right: 24px;
    }
  }
}

.package-radio {
  margin-bottom: 12px;
}

.wid-100 {
  display: flex;
  width: 100% !important;
}



.package-radio {
  width: 420px;

  .el-radio.is-bordered {
    width: 420px;
  }
}

.custom-box {
  width: 100%;
  padding: 12px;
  border: 1px solid #DBDDE6;
}

.wid-100 .el-form-item__content {
  flex: 1;
}

.radio-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #00000030;
  padding: 0 16px;

  >div {
    flex: 1;
  }

  .radio-option-num {
    text-align: right;
  }
}



.form-input-box {
  height: 85px;
}
</style>