<template>
  <div class="questions">
    <div class="insert-title">{{ $t('open.t2') }}</div>
    <el-form :model="formData" :rules="rules" ref="fb" :hide-required-asterisk="true" class="qjc-clearfix qjc-fts-14">
      <!-- 一、基本资料 -->
      <div class="basic qjc-fl qjc-wid-100">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t16') }}</h3>
        <el-form-item prop="area" :label="$t('open.t17')" class="area qjc-fl qjc-wid-50">
          <div class="">
            <el-select v-model="formData.area">
              <el-option v-for="item in areaList" :key="item.code" :label="item.text" :value="item.code"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item :label="$t('open.t18')" prop="drink" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.qndrink" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q1') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q4') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q2') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t19')" prop="smoke" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.qnsmoke" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q5') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q8') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q6') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('open.f1')" prop="qnmh" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.qnmh">
            <el-radio :label="1" border>{{ $t('open.f23') }}</el-radio>
            <label>
              <el-radio :label="2" class="qnmh-radio" border>{{ $t('open.f24') }}</el-radio>
              <el-input type="number" v-if="formData.qnmh == 2" class="qntimes-input" v-model="formData.qntimes"
                :placeholder="$t('open.f12')"></el-input>
            </label>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.f2')" prop="qnpbf" class="qjc-fl qjc-wid-50 margin-bottom-2 ">
          <el-radio-group v-model="formData.qnpbf" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.f23') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.f24') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.f7') }}</el-radio>
          </el-radio-group>
        </el-form-item>



        <el-form-item :label="$t('open.f3')" prop="selfcare" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.selfcare" class="qjc-block">
            <el-radio class="selfcare-radio" :label="1" border>{{ $t('open.f8') }}</el-radio>
            <el-radio class="selfcare-radio" :label="2" border>{{ $t('open.f9') }}</el-radio>
            <el-radio class="selfcare-radio no-margin" :label="3" border>{{ $t('open.f10') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.f4')" prop="tscore" class="qjc-fl qjc-wid-50">
          <el-input type="number" class="tscore-input" v-model="formData.tscore"
            :placeholder="$t('open.f13')"></el-input>
        </el-form-item>
      </div>

      <!-- 二、个人病史 -->
      <div class="fb qjc-fl qjc-wid-50">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t27') }}</h3>

        <el-form-item :label="$t('open.f5')" prop="hasFb">
          <el-radio-group v-model="hasFb" class="qjc-block" @change="hasFb ? '' : (fbCheckList = [])">
            <el-radio :label="false" class="wid-96 hasFb-radio" border>{{ $t('open.q24') }}</el-radio>
            <el-radio :label="true" class="wid-96 no-margin" border>{{ $t('open.q30') }}</el-radio>
          </el-radio-group>

          <el-checkbox-group @change="selectDisease" v-model="formData.fbCheckList" class="qjc-clearfix"
            :disabled="!hasFb">
            <el-checkbox label="F01" class="qjc-fl">结核病</el-checkbox>
            <el-checkbox label="F02" class="qjc-fl">恶性肿瘤</el-checkbox>
            <el-checkbox label="F03" class="qjc-fl">非恶性肿瘤</el-checkbox>
            <el-checkbox label="F04" class="qjc-fl">甲状腺疾病</el-checkbox>
            <el-checkbox label="F05" class="qjc-fl">糖尿病</el-checkbox>
            <el-checkbox label="F06" class="qjc-fl">营养/电解质失衡、贫血疾病</el-checkbox>
            <el-checkbox label="F07" class="qjc-fl">高血脂/高胆固醇</el-checkbox>
            <el-checkbox label="F08" class="qjc-fl">痛风</el-checkbox>
            <el-checkbox label="F09" class="qjc-fl">血液及造血相关疾病</el-checkbox>
            <el-checkbox label="F10" class="qjc-fl">认知障碍/失智症</el-checkbox>
            <el-checkbox label="F11" class="qjc-fl">精神障碍</el-checkbox>
            <el-checkbox label="F12" class="qjc-fl">非精神性心理障碍</el-checkbox>
            <el-checkbox label="F13" class="qjc-fl">中枢神经系统疾病</el-checkbox>
            <el-checkbox label="F14" class="qjc-fl">末稍神经系统疾病</el-checkbox>
            <el-checkbox label="F15" class="qjc-fl">视觉损伤</el-checkbox>
            <el-checkbox label="F16" class="qjc-fl">晕眩及前庭疾病</el-checkbox>
            <el-checkbox label="F17" class="qjc-fl">听觉损伤</el-checkbox>
            <el-checkbox label="F18" class="qjc-fl">高血压</el-checkbox>
            <el-checkbox label="F19" class="qjc-fl">心脏病</el-checkbox>
            <el-checkbox label="F20" class="qjc-fl">脑血管疾病</el-checkbox>
            <el-checkbox label="F21" class="qjc-fl">动脉及毛细血管疾病</el-checkbox>
            <el-checkbox label="F22" class="qjc-fl">静脉及淋巴管疾病</el-checkbox>
            <el-checkbox label="F23" class="qjc-fl">慢性阻塞性肺部疾病</el-checkbox>
            <el-checkbox label="F24" class="qjc-fl">胃肠疾病</el-checkbox>
            <el-checkbox label="F25" class="qjc-fl">慢性肝脏疾病</el-checkbox>
            <el-checkbox label="F26" class="qjc-fl">肾病徵候群</el-checkbox>
            <el-checkbox label="F27" class="qjc-fl">前列腺疾病</el-checkbox>
            <el-checkbox label="F28" class="qjc-fl">女性生殖系统疾病</el-checkbox>
            <el-checkbox label="F29" class="qjc-fl">关节疾病</el-checkbox>
            <el-checkbox label="F30" class="qjc-fl">颈椎及背部疾病</el-checkbox>
            <el-checkbox label="F31" class="qjc-fl">风湿症</el-checkbox>
            <el-checkbox label="F32" class="qjc-fl">肌肉骨骼疾病</el-checkbox>
            <el-checkbox label="F33" class="qjc-fl">睡眠障碍</el-checkbox>
            <el-checkbox label="F34" class="qjc-fl">长期疼痛</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>

      <!-- 三、个人手术史 -->
      <div class="service qjc-fl qjc-wid-50">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t28') }}</h3>

        <el-form-item :label="$t('open.f6')" prop="hasService">
          <el-radio-group v-model="hasService" class="qjc-block" @change="hasService ? '' : (serviceCheckList = [])">
            <el-radio :label="false" class="wid-96 hasFb-radio" border>{{ $t('open.q24') }}</el-radio>
            <el-radio :label="true" class="wid-96 no-margin" border>{{ $t('open.q30') }}</el-radio>
          </el-radio-group>

          <el-checkbox-group v-model="formData.serviceCheckList" class="qjc-clearfix" :disabled="!hasService">
            <el-checkbox label="V01" class="qjc-fl">就医陪伴</el-checkbox>
            <el-checkbox label="V02" class="qjc-fl">专业复健服务</el-checkbox>
            <el-checkbox label="V03" class="qjc-fl">医疗咨询服务</el-checkbox>
            <el-checkbox label="V04" class="qjc-fl">健康管理与促进咨询服务</el-checkbox>
            <el-checkbox label="V05" class="qjc-fl">营养或健康食品咨询服务</el-checkbox>
            <el-checkbox label="V06" class="qjc-fl">用药咨询服务</el-checkbox>
            <el-checkbox label="V07" class="qjc-fl">健康检查咨询服务</el-checkbox>
            <el-checkbox label="V08" class="qjc-fl">陪伴或协助社交活动</el-checkbox>
            <el-checkbox label="V09" class="qjc-fl">陪伴或协助运动</el-checkbox>
            <el-checkbox label="V10" class="qjc-fl">喘息服务</el-checkbox>
            <el-checkbox label="V11" class="qjc-fl">居家清洁卫生服务</el-checkbox>
            <el-checkbox label="V12" class="qjc-fl">洗衣服务</el-checkbox>
            <el-checkbox label="V13" class="qjc-fl">居家安全设计咨询服务</el-checkbox>
            <el-checkbox label="V14" class="qjc-fl">供餐服务</el-checkbox>
            <el-checkbox label="V15" class="qjc-fl">探视与陪伴服务</el-checkbox>
            <el-checkbox label="V16" class="qjc-fl">居家照护人力提供服务</el-checkbox>
            <el-checkbox label="V17" class="qjc-fl">保险理财咨询服务</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>

      <!-- <el-form-item class="qjc-fl qjc-wid-100 qjc-texta-c"><el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button></el-form-item> -->
    </el-form>

    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-block">{{ $t('open.t61') }}</el-button>
    </div>
  </div>
</template>

<script>

import {
  packageOpen
} from '@/utils/axios'
import {
  Base64
} from 'js-base64'


export default {
  name: 'MoreInfoFb',
  data () {
    //验证
    // 是否患有一下疾病
    var fbdiseaseVerify = (rule, value, callback) => {
      if (!this.hasFb) {
        callback(new Error(this.$t('open.f14')));
      } else if (this.hasFb && this.fbCheckList.length > 0) {
        callback();
      }
    };
    return {
      rules: {
        area: [{ required: true, message: this.$t('open.f15') }],
        qndrink: [{ required: true, message: this.$t('open.f16') }],
        qnsmoke: [{ required: true, message: this.$t('open.f17') }],
        qnmh: [{ required: true, message: this.$t('open.f18') }],
        qnpbf: [{ required: true, message: this.$t('open.f19') }],
        selfcare: [{ required: true, message: this.$t('open.f20') }],
        tscore: [{ required: false, message: this.$t('open.f21') }],
        qntimes: [{ required: false, message: this.$t('open.f22') }],
        fbdisease: [{ validator: fbdiseaseVerify, trigger: 'blur' }],
      },

      // 居住地
      areaList: [
        { text: "中国地区", code: '9999' },
        { text: "台湾地区", code: '0000' },
      ],
      fbdiseaseList: [
        { "fdis": "F00", "level": "1" },
        { "fdis": "F01", "level": "0" },
        { "fdis": "F02", "level": "0" },
        { "fdis": "F03", "level": "0" },
        { "fdis": "F04", "level": "0" },
        { "fdis": "F05", "level": "0" },
        { "fdis": "F06", "level": "0" },
        { "fdis": "F07", "level": "0" },
        { "fdis": "F08", "level": "0" },
        { "fdis": "F09", "level": "0" },
        { "fdis": "F10", "level": "0" },
        { "fdis": "F11", "level": "0" },
        { "fdis": "F12", "level": "0" },
        { "fdis": "F13", "level": "0" },
        { "fdis": "F14", "level": "0" },
        { "fdis": "F15", "level": "0" },
        { "fdis": "F16", "level": "0" },
        { "fdis": "F17", "level": "0" },
        { "fdis": "F18", "level": "0" },
        { "fdis": "F19", "level": "0" },
        { "fdis": "F20", "level": "0" },
        { "fdis": "F21", "level": "0" },
        { "fdis": "F22", "level": "0" },
        { "fdis": "F23", "level": "0" },
        { "fdis": "F24", "level": "0" },
        { "fdis": "F25", "level": "0" },
        { "fdis": "F26", "level": "0" },
        { "fdis": "F27", "level": "0" },
        { "fdis": "F28", "level": "0" },
        { "fdis": "F29", "level": "0" },
        { "fdis": "F30", "level": "0" },
        { "fdis": "F31", "level": "0" },
        { "fdis": "F32", "level": "0" },
        { "fdis": "F33", "level": "0" },
        { "fdis": "F34", "level": "0" }
      ],
      serviceList: [
        { "svc": "V01", "need": "1" },
        { "svc": "V02", "need": "1" },
        { "svc": "V03", "need": "1" },
        { "svc": "V04", "need": "1" },
        { "svc": "V05", "need": "1" },
        { "svc": "V06", "need": "1" },
        { "svc": "V07", "need": "1" },
        { "svc": "V08", "need": "1" },
        { "svc": "V09", "need": "1" },
        { "svc": "V10", "need": "1" },
        { "svc": "V11", "need": "1" },
        { "svc": "V12", "need": "1" },
        { "svc": "V13", "need": "1" },
        { "svc": "V14", "need": "1" },
        { "svc": "V15", "need": "1" },
        { "svc": "V16", "need": "1" },
        { "svc": "V17", "need": "1" },
      ],
      hasDisease: '', //是否患有指定疾病
      hasFb: false,
      hasService: false,
      fbCheckList: [],
      serviceCheckList: [],
    };
  },
  props: ['type', 'formData'],
  created () {
    if (this.formData.fbCheckList.length) {
      this.hasFb = true
    } else {
      this.hasFb = false
    }

    if (this.formData.serviceCheckList.length) {
      this.hasService = true
    } else {
      this.hasService = false
    }


  },
  mounted () {
    //数据修改时  根据已有数据显示内容


  },
  methods: {
    // 下一步
    next () {
      if (this.hasFb && this.formData.fbCheckList.length <= 0) {
        this.$myAlert({
          type: 'fail',
          content: '请选择患病情况'
        });
        return
      }
      if (this.hasFb && this.formData.fbCheckList.length > 0) {

        const arr = this.fbdiseaseList.map(item => {
          if (this.formData.fbCheckList.includes(item.fdis)) {
            item.level = 1
          } else {
            item.level = 0
          }
          return item
        })
        arr[0].level = 0
        this.formData.disease = arr
      } else {
        this.formData.disease = this.fbdiseaseList
      }

      if (this.hasService && this.formData.serviceCheckList.length <= 0) {
        this.$myAlert({
          type: 'fail',
          content: '请选择是否需要医护服务'
        });
        return
      }
      if (this.hasService && this.formData.serviceCheckList.length > 0) {
        const arr = this.serviceList.map(item => {
          if (this.formData.serviceCheckList.includes(item.svc)) {
            item.need = 2
          } else {
            item.need = 1
          }
          return item
        })
        this.formData.service = arr
      } else {
        this.formData.service = this.serviceList
      }
      if (this.formData.qnmh == '2') {
        if (this.formData.qntimes <= 0) {
          this.$myAlert({
            type: 'fail',
            content: '请填写曾经骨折次数'
          });
          return
        }
      }
      this.$refs.fb.validate((isSuc, err) => {
        if (isSuc) {
          const formData = JSON.parse(JSON.stringify(this.formData))
          delete formData.famdis
          delete formData.fat
          delete formData.habeat
          delete formData.habsport
          delete formData.smoke
          delete formData.drink
          delete formData.sleep
          delete formData.vege
          delete formData.oper
          delete formData.examdate
          delete formData.examplace

          delete formData.prebust
          delete formData.prefat
          delete formData.prewaist
          delete formData.predis2
          delete formData.preweek
          delete formData.preweight
          delete formData.prehip
          delete formData.week
          delete formData.aps1
          delete formData.bsex
          delete formData.bweight
          delete formData.mage
          delete formData.dbp
          delete formData.sbp
          delete formData.chest

          delete formData.examine
          delete formData.sbpCombo
          delete formData.fbCheckList
          delete formData.serviceCheckList

          if (this.type == 'update') {
            formData.act = 'update'
          }
          delete formData.fbdisease
          delete formData.package
          formData.operator = localStorage.getItem('mobile');
          formData.birth = formData.birth.split('/').join('')

          const goods_type = formData.goods_type
          delete formData.goods_type
          console.log("formData", formData);


          packageOpen(formData).then(res => {
            this.submiting = false;

            if (res.code == 200) {
              // this.updateShow = false;
              const { id } = JSON.parse(Base64.decode(res.data))
              this.download(goods_type, id)
              this.$emit("resetFormData");

            } else {
              // this.updateShow = false;
              this.$myAlert({
                type: 'fail',
                content: res.msg
              });
            }
          });
        }
      });
    },

    download (type, id) {
      if (type == 'AIMB') {// 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });
        window.open(openUrl.href);
      }

    },

    handlePrev () {
      this.$emit('prevStep');
    },
    selectDisease (e) {
      console.log("e", e);
    }
  }
};
</script>

<style scoped>
.el-form>>>.el-form-item__label {
  width: 100%;
  text-align: left;
  color: #40444d;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
}

.area>>>.el-form-item__label {
  /* width: 111px; */
}

.el-form>>>.el-form-item__content {
  line-height: 1;
}

.area>>>input {
  width: 190px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 12px;
}

.area>>>.el-input .el-select__caret {
  font-size: 14px;
  line-height: 32px;
}

.el-form>>>.el-radio__label {
  font-size: 14px;
  color: #777f8f;
}

.el-form>>>.diseaseTd {
  color: #777f8f;
  background-color: #f2f4fd;
  padding: 10px 16px;
  line-height: 14px;
  border: 0.5px solid #fff;
}

.el-table>>>.el-checkbox__inner {
  border-color: #777f8f;
}

.el-table>>>.cell {
  padding: 0;
  line-height: 14px;
}

.el-table>>>.el-table__body,
.el-table>>>.el-table__footer,
.el-table>>>.el-table__header {
  table-layout: auto;
}

.el-table>>>.name {
  width: 153px;
}

.el-table>>>.no {
  width: 105px;
}

.el-table>>>.acc {
  width: 120px;
}

.el-table>>>.often {
  width: 136px;
}

.oper>>>.el-checkbox__label,
.famdis>>>.el-checkbox__label {
  font-size: 14px;
}

.oper>>>.el-checkbox__inner,
.famdis>>>.el-checkbox__inner {
  border-color: #777f8f;
}

.questions>>>.el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #777f8f;
  font-weight: normal;
  font-size: 14px;
}

.margin-bottom-2 {
  height: 80px;
}

.questions>>>.el-radio.is-bordered {
  height: 34px;
  line-height: 32px;
  padding: 0 12px;
  font-size: 14px;
  color: #525866;
  border: 1px solid #CED4F0;
}

.questions>>>.el-input__inner {
  height: 34px;
  line-height: 34px;
}

.questions>>>.el-form .el-form-item .el-radio {
  min-width: 130px;
}
</style>
<style lang="scss" scoped>
.questions {
  padding-top: 50px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    h3 {
      line-height: 16px;
      margin-bottom: 24px;
    }

    .basic {
      margin-bottom: 40px;

      .el-form-item {
        width: 50%;
      }
    }

    .el-form-item {
      margin-bottom: 25px;

      .area-label {
        line-height: 32px;
      }

      .el-radio {
        margin-bottom: 15px;
        margin-right: 0;
      }

      &.sleep {
        margin-top: -29px;
      }

      .el-table {
        width: 96%;
        margin-left: 24px;
      }

      .next {
        width: 200px;
        height: 48px;
        margin-top: 47px;
      }

      .tscore-input {
        width: 300px;
      }

      .qntimes-input {
        width: 200px;

      }
    }

    .el-radio {
      font-weight: normal;
    }

    .oper,
    .fb,
    .service,
    .famdis {
      .el-checkbox-group {
        width: 96%;
        background-color: #f2f4fd;
        // margin-left: 0.24px;

        .el-checkbox {
          width: 33.33%;
          line-height: 14px;
          margin-right: 0;
          padding: 10px 16px;
          border: 0.5px solid #fff;
          color: #777f8f;
        }
      }
    }

    .oper {
      margin-bottom: 88px;
    }
  }

  .no-margin {
    margin-left: 0 !important;
  }

  .qnmh-radio {
    margin-left: 15px;
  }

  .wid-96 {
    width: 96%;
  }

  .hasFb-radio {
    width: 96%;
    padding-right: 20px;
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }
}

.selfcare-radio {
  min-width: 273px !important;
}
</style>
