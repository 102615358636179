<template>
  <div class="questions">
    <div class="insert-title">{{ $t('open.t2') }}</div>
    <el-form :model="formData" :rules="rules" ref="questions" :hide-required-asterisk="true"
      class="qjc-clearfix qjc-fts-14">
      <!-- 一、基本资料 -->
      <div class="basic qjc-fl qjc-wid-100">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t16') }}</h3>

        <el-form-item prop="area" :label="$t('open.t17')" class="area qjc-fl qjc-wid-50">

          <div class="">
            <el-select v-model="formData.area">
              <el-option v-for="item in areaList" :key="item.code" :label="item.text" :value="item.code"></el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item :label="$t('open.t18')" prop="drink" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.drink" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q1') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q2') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q3') }}</el-radio>
            <el-radio :label="4" border>{{ $t('open.q4') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t19')" prop="smoke" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.smoke" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q5') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q6') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q7') }}</el-radio>
            <el-radio :label="4" border>{{ $t('open.q8') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t20')" prop="habeat" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.habeat" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q9') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q10') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q11') }}</el-radio>
            <el-radio :label="4" border>{{ $t('open.q12') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t21')" prop="vege" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.vege" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q13') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q14') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t22')" prop="habsport" class="qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.habsport" class="qjc-block">
            <el-radio class="sport-radio" :label="1" border>{{ $t('open.q15') }}</el-radio>
            <el-radio class="sport-radio" :label="2" border>{{ $t('open.q16') }}</el-radio>
            <el-radio class="sport-radio" :label="3" border>{{ $t('open.q17') }}</el-radio>
            <el-radio class="sport-radio" :label="4" border>{{ $t('open.q18') }}</el-radio>
            <el-radio class="sport-radio" :label="5" border>{{ $t('open.q19') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="$t('open.t23')" prop="sleep" class="sleep qjc-fl qjc-wid-50">
          <el-radio-group v-model="formData.sleep" class="qjc-block">
            <el-radio :label="1" border>{{ $t('open.q20') }}</el-radio>
            <el-radio :label="2" border>{{ $t('open.q21') }}</el-radio>
            <el-radio :label="3" border>{{ $t('open.q22') }}</el-radio>
            <el-radio :label="4" border>{{ $t('open.q23') }}</el-radio>
          </el-radio-group>
        </el-form-item>

      </div>

      <!-- 二、个人病史 -->
      <div class="qjc-fl qjc-wid-50">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t27') }}</h3>

        <el-form-item :label="$t('open.t24')" prop="disease" class="disease">
          <el-radio-group v-model="hasDisease" class="qjc-block" @change="checkDis">
            <el-radio :label="false" border class="qjc-wid-100">{{ $t('open.q24') }}</el-radio>
            <el-radio :label="true" border class="qjc-wid-100">{{ $t('open.q25') }}</el-radio>
          </el-radio-group>

          <el-table :data="formData.disease" header-row-class-name="qjc-fts-14 qjc-c-dark qjc-texta-c"
            :header-cell-style="{ backgroundColor: '#E7E9F0', border: '0.005rem solid #fff' }"
            cell-class-name="diseaseTd qjc-fts-14">
            <el-table-column :label="$t('open.q26')" class-name="name" header-align="center">
              <template slot-scope="scope">
                <span>{{ getDisName(scope.row.kdis ? scope.row.kdis : scope.row.KDIS) }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('open.q27')" class-name="no" header-align="center" align="center">
              <template slot-scope="scope">
                <el-checkbox :value="(scope.row.level ? scope.row.level : scope.row.LEVEL) == '3'"
                  @change="levelChange(scope.row, '3')"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column :label="$t('open.q28')" class-name="occ" header-align="center" align="center">
              <!-- :disabled="!hasDisease" -->
              <template slot-scope="scope">
                <el-checkbox :value="(scope.row.level ? scope.row.level : scope.row.LEVEL) == '2'"
                  @change="levelChange(scope.row, '2')"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column :label="$t('open.q29')" class-name="ofen" header-align="center" align="center">
              <template slot-scope="scope">
                <el-checkbox :value="(scope.row.level ? scope.row.level : scope.row.LEVEL) == '1'"
                  @change="levelChange(scope.row, '1')"></el-checkbox>
              </template>
            </el-table-column>

          </el-table>

        </el-form-item>
      </div>

      <!-- 三、个人手术史 -->
      <div class="oper qjc-fl qjc-wid-50">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t28') }}</h3>

        <el-form-item :label="$t('open.t25')" prop="oper">

          <!-- @change="hasOper?'':formData.oper = []" -->
          <el-radio-group v-model="hasOper" class="qjc-block" @change="handleOper">
            <el-radio :label="false" border class="qjc-wid-100">{{ $t('open.q24') }}</el-radio>
            <el-radio :label="true" border class="qjc-wid-100">{{ $t('open.q30') }}</el-radio>
          </el-radio-group>

          <el-checkbox-group v-model="formData.oper" class="qjc-clearfix" @change="operChange">
            <el-checkbox label="1" class="qjc-fl">
              头或脑部
            </el-checkbox>
            <el-checkbox label="2" class="qjc-fl">
              眼鼻耳
            </el-checkbox>
            <el-checkbox label="3" class="qjc-fl">
              口腔、食道
            </el-checkbox>
            <el-checkbox label="4" class="qjc-fl">
              胸部(心、肺等)
            </el-checkbox>
            <el-checkbox label="5" class="qjc-fl">
              腹部(胃、肝、胆等)
            </el-checkbox>
            <el-checkbox label="6" class="qjc-fl">
              关节与骨
            </el-checkbox>
            <el-checkbox label="7" class="qjc-fl">
              妇科手术
            </el-checkbox>
            <el-checkbox label="8" class="qjc-fl">
              泌尿系统
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>
      </div>

      <!-- 四、家族病史 -->
      <div class="famdis qjc-fl qjc-wid-50">
        <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">{{ $t('open.t29') }}</h3>

        <el-form-item :label="$t('open.t26')" prop="famdis">
          <el-radio-group v-model="hasFamdis" class="qjc-block" @change="handleFamdis">
            <el-radio :label="false" border class="qjc-wid-100">{{ $t('open.q20') }}</el-radio>
            <el-radio :label="true" border class="qjc-wid-100">{{ $t('open.q30') }}</el-radio>
          </el-radio-group>

          <el-checkbox-group v-model="formData.famdis" class="qjc-clearfix" @change="famdisChange">
            <el-checkbox label="1" class="qjc-fl">
              癌症
            </el-checkbox>
            <el-checkbox label="2" class="qjc-fl">
              脑中风
            </el-checkbox>
            <el-checkbox label="3" class="qjc-fl">
              心肌梗塞
            </el-checkbox>
            <el-checkbox label="4" class="qjc-fl">
              肝硬化
            </el-checkbox>
            <el-checkbox label="5" class="qjc-fl">
              老年失智
            </el-checkbox>
            <el-checkbox label="6" class="qjc-fl">
              慢性阻塞性肺炎
            </el-checkbox>
            <el-checkbox label="7" class="qjc-fl">
              结核病
            </el-checkbox>
            <el-checkbox label="8" class="qjc-fl">
              先天畸形
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>
      </div>
    </el-form>
    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{ $t('open.t12') }}</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">{{ $t('open.t61') }}</el-button>
    </div>
  </div>

</template>

<script>

import {
  packageOpen
} from '@/utils/axios'
import {
  Base64
} from 'js-base64'
import { formData_default } from '../../question';

export default {
  name: 'MoreInfoZhihui',
  data () {
    //验证
    // 是否患有一下疾病
    var diseaseVerify = (rule, value, callback) => {
      if (this.hasDisease === '') {
        callback(new Error('请选择是否患有以下疾病'));
      } else if (this.hasDisease) {
        if (value.filter(item => item.level != '0').length > 0) {
          callback();
        } else {
          callback(new Error('请选择服药情况'));
        }
      } else {
        callback();
      }
    }
    // 手术史
    var operVerify = (rule, value, callback) => {
      if (this.hasOper === '') {
        callback(new Error('请选择是否做过下列手术'));
      } else if (this.hasOper) {
        if (value.length > 0) {
          callback();
        } else {
          callback(new Error('请选择'));
        }
      } else {
        callback();
      }
    }
    // 家族病史
    var famdisVerify = (rule, value, callback) => {
      if (this.hasFamdis === '') {
        callback(new Error('请选择直系亲属是否存在下列疾病'));
      } else if (this.hasFamdis) {
        if (value.length > 0) {
          callback();
        } else {
          callback(new Error('请选择'));
        }
      } else {
        callback();
      }
    }

    return {
      rules: {
        area: [
          { required: true, message: '请选择居住地' }
        ],
        drink: [
          { required: true, message: '请选择喝酒情况' }
        ],
        smoke: [
          { required: true, message: '请选择是否吸烟' }
        ],
        habeat: [
          { required: true, message: '请选择用餐习惯' }
        ],
        vege: [
          { required: true, message: '请选择是否为全日素食者' }
        ],
        habsport: [
          { required: true, message: '请选择运动习惯' }
        ],
        sleep: [
          { required: true, message: '请选择是否存在睡眠障碍' }
        ],
        disease: [
          { validator: diseaseVerify, trigger: 'blur' }
        ],
        oper: [
          { validator: operVerify, trigger: 'blur' }
        ],
        famdis: [
          { validator: famdisVerify, trigger: 'blur' }
        ]
      },

      areaList: [
        { text: "江苏省", code: '3200' },
        { text: "上海市", code: '3100' },
        { text: "北京市", code: '1100' },
        { text: "天津市", code: '1200' },
        { text: "浙江省", code: '3300' },
        { text: "广东省", code: '4400' },
        { text: "辽宁省", code: '2100' },
        { text: "山东省", code: '3700' },
        { text: "内蒙古自治区", code: '1500' },
        { text: "重庆市", code: '5000' },
        { text: "福建省", code: '3500' },
        { text: "海南省", code: '4600' },
        { text: "湖北省", code: '4200' },
        { text: "湖南省", code: '4300' },
        { text: "陕西省", code: '6100' },
        { text: "河南省", code: '4100' },
        { text: "黑龙江省", code: '2300' },
        { text: "青海省", code: '6300' },
        { text: "云南省", code: '5300' },
        { text: "安徽省", code: '3400' },
        { text: "四川省", code: '5100' },
        { text: "贵州省", code: '5200' },
        { text: "甘肃省", code: '6200' },
        { text: "江西省", code: '3600' },
        { text: "吉林省", code: '2200' },
        { text: "山西省", code: '1400' },
        { text: "河北省", code: '1300' },
        { text: "宁夏回族自治区", code: '6400' },
        { text: "广西壮族自治区", code: '4500' },
        { text: "新疆维吾尔自治区", code: '6500' },
        { text: "西藏自治区", code: '5400' },
        { text: "中国台湾", code: '0000' },
        { text: "中国香港", code: '4401' }
      ],

      diseaseNames: [
        { "kdis": "K01", "name": "高血压" },
        { "kdis": "K02", "name": "高血脂" },
        { "kdis": "K03", "name": "糖尿病" },
        { "kdis": "K04", "name": "慢性肝病" },
        { "kdis": "K05", "name": "慢性肾脏病" },
        { "kdis": "K06", "name": "癌症" },
        { "kdis": "K07", "name": "肝硬化" },
        { "kdis": "K08", "name": "慢性阻塞性肺炎" },
        { "kdis": "K09", "name": "关节炎" },
        { "kdis": "K10", "name": "心脏病" },
        { "kdis": "K11", "name": "脑中风" },
        { "kdis": "K12", "name": "胃肠溃疡" },
        { "kdis": "K13", "name": "睡眠障碍" },
        // 新增  健康评分
        { "kdis": "K14", "name": "贫血" },
        { "kdis": "K15", "name": "疼痛" },
        { "kdis": "K16", "name": "哮喘" },
        { "kdis": "K17", "name": "痛风" },
        { "kdis": "K18", "name": "痔疮" },
        { "kdis": "K19", "name": "甲状腺疾病" },
      ],//kids所对应疾病


      hasDisease: false,//是否患有指定疾病
      hasOper: false,//是否有手术史
      hasFamdis: false//是否有家族病史
    }
  },
  props: ['type', 'formData'],
  mounted () {
    this.areaList = this.$t('areaList')
    //数据修改时  根据已有数据显示内容
    if (this.type == 'update') {
      //个人病史
      var dis = this.formData.disease.filter(d => d.level != 0);
      if (dis.length > 0) {
        this.hasDisease = true;
      } else {
        this.hasDisease = false;
      }

      //个人手术史
      if (this.formData.oper === '') {// 若为空，改为空数组
        this.formData.oper = [];
      }
      if (this.formData.oper && this.formData.oper.length > 0) {
        this.hasOper = true;
      } else {
        this.hasOper = false;
      }

      //家族病史
      if (this.formData.famdis === '') {// 若为空，改为空数组
        this.formData.famdis = [];
      }
      if (this.formData.famdis && this.formData.famdis.length > 0) {
        this.hasFamdis = true;
      } else {
        this.hasFamdis = false;
      }
    }

    this.formData.disease.map((item) => {
      if (item.level != 0) {
        this.hasDisease = true
      }
    })

    if (this.formData.oper && this.formData.oper.length > 0) {
      this.hasOper = true
    }
    if (this.formData.famdis && this.formData.famdis.length > 0) {
      this.hasFamdis = true
    }
  },

  methods: {
    // 下一步
    next () {
      this.$refs.questions.validate((isSuc, err) => {
        if (isSuc) {
          const formData = JSON.parse(JSON.stringify(this.formData))

          delete formData.qndrink
          delete formData.qnsmoke
          delete formData.qnmh
          delete formData.qnpbf
          delete formData.qntimes
          delete formData.selfcare
          delete formData.tscore
          delete formData.service
          delete formData.fbdisease
          delete formData.chest
          delete formData.examine
          delete formData.sbpCombo
          delete formData.examdate
          delete formData.examplace

          delete formData.prebust
          delete formData.prefat
          delete formData.prewaist
          delete formData.predis2
          delete formData.preweek
          delete formData.preweight
          delete formData.week
          delete formData.aps1
          delete formData.bsex
          delete formData.bweight
          delete formData.mage
          delete formData.prehip
          delete formData.dbp
          delete formData.sbp

          if (this.type == 'update') {
            formData.act = 'update'
          }

          formData.operator = localStorage.getItem('mobile');
          formData.birth = formData.birth.split('/').join('')
          delete formData.package

          const goods_type = formData.goods_type
          delete formData.goods_type

          console.log("formData", formData);

          packageOpen(formData).then(res => {
            this.submiting = false;

            if (res.code == 200) {
              // this.updateShow = false;
              const {
                id
              } = JSON.parse(Base64.decode(res.data))
              this.download(goods_type, id)
              this.$emit("resetFormData")

            } else {
              // this.updateShow = false;
              this.$myAlert({
                type: 'fail',
                content: res.msg
              });
            }
          });

        }
      });
    },

    download (type, id) {
      if (type == 'AIMB') { // 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });
        window.open(openUrl.href);
      }

    },

    // 获取对应kdis疾病名称
    getDisName (kdis) {
      let name = '';
      this.diseaseNames.forEach((item) => {
        if (item.kdis == kdis) {
          name = item.name;
          return;
        }
      })
      return name;
    },

    checkDis (value) {
      if (!value) {// 不存在指定疾病 清0
        this.formData.disease.forEach((item) => {
          item.level = '0';
          this.hasDisease = false
        })
      }
    },

    // 切换服药状态 参数：row:当前所属疾病data   level: level将要改变的值
    levelChange (row, level) {
      if (row.level == level) {
        row.level = '0';
      } else {
        row.level = level;
        this.hasDisease = true
      }
      let hasDis = false
      this.formData.disease.forEach((item, num) => {
        if (item.level != 0) {
          hasDis = true
        }
      })
      if (!hasDis) {
        this.hasDisease = false
      }
    },
    handleOper (value) {
      if (!value) {// 不存在指定疾病 清0
        this.formData.oper = []
      }
    },
    operChange (val) {
      if (val && val.length > 0) {
        this.hasOper = true
      } else {
        this.hasOper = false
      }
    },
    handleFamdis (value) {
      if (!value) {// 不存在指定疾病 清0
        this.formData.famdis = []
      }
    },
    famdisChange (val) {
      if (val && val.length > 0) {
        this.hasFamdis = true
      } else {
        this.hasFamdis = false
      }
    },
    handlePrev () {
      this.$emit('prevStep');
    },
  }
}
</script>

<style scoped>
.el-form>>>.el-form-item__label {
  width: 100%;
  text-align: left;
  color: #40444D;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
}



.el-form>>>.el-form-item__content {
  line-height: 1;
}

.area>>>input {
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 12px;
}

.area>>>.el-select {
  width: 100%;
  padding-right: 20px;
}

.area>>>.el-input .el-select__caret {
  font-size: 14px;
  line-height: 32px;
}

.el-form>>>.el-radio__label {
  font-size: 14px;
  /* color: #777F8F; */
}

.el-form>>>.diseaseTd {
  color: #777F8F;
  background-color: #F2F4FD;
  padding: 10px 16px;
  line-height: 14px;
  border: 1px solid #fff;
}

.disease {
  padding-right: 20px;
}

.el-table>>>.el-checkbox__inner {
  border-color: #777F8F;
}

.el-table>>>.cell {
  padding: 0;
  line-height: 14px;
}

.el-table>>>.el-table__body,
.el-table>>>.el-table__footer,
.el-table>>>.el-table__header {
  table-layout: auto;
}

.el-table>>>.name {
  width: 153px;
}

.el-table>>>.no {
  width: 105px;
}

.el-table>>>.acc {
  width: 120px;
}

.el-table>>>.often {
  width: 136px;
}

.oper>>>.el-checkbox__label,
.famdis>>>.el-checkbox__label {
  font-size: 14px;
}

.oper>>>.el-checkbox__inner,
.famdis>>>.el-checkbox__inner {
  border-color: #777F8F;
}

.questions>>>.el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #777F8F;
  font-weight: normal;
}

.questions>>>.el-radio {
  margin-right: 10px;
  margin-left: 0 !important;
}

.questions>>>.el-radio__input.is-checked .el-radio__inner,
.el-radio__input.is-checked+.el-radio__label {
  color: #6681FA !important;
}

.questions>>>.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  color: #6681FA;
  background-color: #6681FA;
}

.questions>>>.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #6681FA;
}

.questions>>>.el-form .el-form-item .el-radio {
  min-width: 130px;
}
</style>
<style lang="scss" scoped>
.questions {
  padding-top: 50px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .group-padding {
    padding-right: 20px;
  }

  .el-radio.is-bordered {
    height: 34px;
    line-height: 32px;
    padding: 0 12px;
    font-size: 14px;
    color: #525866;
    font-weight: 400;
    border: 1px solid #CED4F0;
  }

  .el-radio.is-bordered.is-checked {
    border-color: #6681FA;
  }

  .el-form {
    h3 {
      line-height: 16px;
      margin-bottom: 24px;
    }

    .basic {
      margin-bottom: 40px;

      .el-form-item {
        width: 50%;
      }
    }

    .el-form-item {
      margin-bottom: 25px;

      .area-label {
        line-height: 32px;
      }

      .el-radio {
        margin-bottom: 8px;
      }

      &.sleep {
        margin-top: -29px;
      }

      .el-table {
        width: 100%;
        // padding-right: 20px;
        // margin-top: -8px;
      }
    }

    .el-radio {
      font-weight: normal;
    }

    .oper,
    .famdis {
      .el-checkbox-group {
        width: 100%;
        background-color: #F2F4FD;
        // margin-left: 24px;

        .el-checkbox {
          width: 33.33%;
          line-height: 14px;
          margin-right: 0;
          padding: 10px 16px;
          border: 1px solid #fff;
          color: #777F8F;
        }
      }
    }

    .oper {
      margin-bottom: 88px;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }
}

.sport-radio {
  min-width: 273px !important;
}
</style>
