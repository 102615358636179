<template>
  <div class="question-zhihui">
    <div class="header-step">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="selectTab(0)">
            <el-image class="insert-icon" v-if="tab == 0"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-else :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="selectTab(1)">
            <el-image v-if="tab == 1" class="insert-icon" :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-else-if="tab < 1" class="insert-icon" :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon" v-else :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="selectTab(2)">
            <el-image v-if="tab == 2" class="insert-icon" :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-else-if="tab < 2" class="insert-icon" :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon" v-else :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t62')">
          <div class="icon-box" slot="icon" @click="selectTab(3)">
            <el-image v-if="tab == 3" class="insert-icon" :src="require('@/assets/images/gravida-int.png')"></el-image>
            <el-image v-else-if="tab < 3" class="insert-icon" :src="require('@/assets/images/gravida.png')"></el-image>
            <el-image class="insert-icon" v-else :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t63')">
          <div class="icon-box" slot="icon" @click="selectTab(4)">
            <el-image v-if="tab == 4" class="insert-icon" :src="require('@/assets/images/newborn-int.png')"></el-image>
            <el-image v-else-if="tab < 4" class="insert-icon" :src="require('@/assets/images/newborn.png')"></el-image>
            <el-image class="insert-icon" v-else :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="selectTab(5)">
            <el-image v-if="tab == 5" class="insert-icon" :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image v-else class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="content-step">
      <personal-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-if="tab == 0"></personal-mb>
      <more-info-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-else-if="tab == 1"></more-info-mb>
      <bodily-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-else-if="tab == 2"></bodily-mb>
      <puerpera-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-else-if="tab == 3"></puerpera-mb>
      <newborn-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-else-if="tab == 4"></newborn-mb>
      <biochemical-mb :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep" :type="type"
        @prevStep="prevStep" v-else-if="tab == 5"></biochemical-mb>
    </div>
  </div>

</template>

<script>
import PersonalMb from './Personal'
import MoreInfoMb from './MoreInfo'
import BiochemicalMb from './Biochemical'
import PuerperaMb from './Puerpera'
import NewbornMb from './Newborn'
import BodilyMb from './Bodily'

export default {
  name: 'QuestionMb',
  data () {
    return {
      tab: 0,
    }
  },
  components: {
    PersonalMb,
    MoreInfoMb, BiochemicalMb, PuerperaMb, NewbornMb, BodilyMb
  },
  props: {

    dataType: {
      type: String,
      default: () => ""
    },
    formData: {
      type: Object,
      default: () => ({})
    }, cancelEdit: {
      type: Function,
      default: () => { }
    },
    type: {
      type: String,
      default: () => ""
    }
  },
  mounted () {
    const that = this
    setTimeout(() => {
      console.log(that.formData, that);
    }, 2000)
  },
  watch: {},
  methods: {
    selectTab (index) {

      this.tab = Math.min(index, this.tab);
    },
    nextStep () {
      this.tab++;
    },
    prevStep () {
      this.tab--;
    },
    resetFormData () {
      console.log("QuestionZhihui resetFormData");
      this.$emit('resetFormData')
    }
  }
}
</script>

<style scoped>
.header-step>>>.el-step__icon {
  border: none;
}
</style>

<style lang="scss" scoped>
.question-zhihui {
  position: relative;
  width: 100%;

  .header-step {
    position: relative;
    padding: 20px 20%;


    .el-step__icon {
      border: none;
    }
  }

  .content-step {
    position: relative;
    padding: 10px;
  }



}


.prev {
  width: 96px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #6883FB;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6883FB;
  background-color: #fff;
  margin-right: 24px;
}
</style>