<template>
  <div class="question-zhihui">
    <div class="header-step">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="selectTab(0)">
            <el-image class="insert-icon" v-if="tab == 0"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-else-if="tab > 0"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>

        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
            <el-image v-if="tab == 1" class="insert-icon" :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image v-else class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>

      </el-steps>
    </div>
    <div class="content-step">
      <personal-m22 :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep"
        :cancelEdit="cancelEdit" :type="type" @prevStep="prevStep" v-if="tab == 0"></personal-m22>

      <biochemical-m22 :formData="formData" @resetFormData="resetFormData" :step="tab" @nextStep="nextStep"
        :cancelEdit="cancelEdit" :type="type" @prevStep="prevStep" v-else-if="tab == 1"></biochemical-m22>
    </div>
  </div>
</template>

<script>
import PersonalM22 from './Personal'
import MoreInfoM22 from './MoreInfo'
import BiochemicalM22 from './Biochemical'

export default {
  name: 'QuestionM22',
  data () {
    return {
      tab: 0,
    }
  },
  components: {
    PersonalM22, MoreInfoM22, BiochemicalM22
  },
  props: {

    dataType: {
      type: String,
      default: () => ""
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    cancelEdit: {
      type: Function,
      default: () => { }
    },
    type: {
      type: String,
      default: () => ""
    }
  },
  mounted () {
    const that = this

  },
  watch: {},
  methods: {
    selectTab (index) {

      this.tab = Math.min(index, this.tab);
    },
    nextStep () {
      this.tab++;
    },
    prevStep () {
      this.tab--;
    },
    resetFormData () {
      console.log("QuestionZhihui resetFormData");
      this.$emit('resetFormData')
    }
  }
}
</script>

<style scoped>
.header-step>>>.el-step__icon {
  border: none;
}
</style>

<style lang="scss" scoped>
.question-zhihui {
  position: relative;
  width: 100%;

  .header-step {
    position: relative;
    padding: 20px 20%;


    .el-step__icon {
      border: none;
    }
  }

  .content-step {
    position: relative;
    padding: 10px;
  }
}
</style>