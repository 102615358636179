<template>
  <div class="new-question">
    <question-bj :formData="formData" @resetFormData="resetFormData" :cancelEdit="cancelEdit" :type="type"
      v-if="goods_type == 'AIBG'"></question-bj>
    <question-fb :formData="formData" @resetFormData="resetFormData" :cancelEdit="cancelEdit" :type="type"
      v-else-if="goods_type == 'AIFB'"></question-fb>
    <question-m22 :formData="formData" @resetFormData="resetFormData" :cancelEdit="cancelEdit" :type="type"
      v-else-if="goods_type == 'M22'"></question-m22>
    <question-mb :formData="formData" @resetFormData="resetFormData" :cancelEdit="cancelEdit" :type="type"
      v-else-if="goods_type == 'AIMB'"></question-mb>
    <question-zhihui :formData="formData" @resetFormData="resetFormData" :cancelEdit="cancelEdit" :type="type"
      v-else-if="goods_type == 'AIZH'"></question-zhihui>
    <div class="package-select" v-else>
      <div class="insert-title" slot="label">
        {{ $t('open.t11') }}
      </div>
      <div class="package-select-content">
        <div class="package" v-for="(goods) in goodsList" :key="goods.goods_id" @click="handlePackage(goods)"
          :title="goods.name">
          <div class="radio-option">
            <el-radio v-model="goods_id"
              :disabled="(pay_mode == 1 && ((goods.times | 0) - (goods.used | 0) <= 0)) || type === 'update'"
              :label="goods.goods_id" @change="handlePackage(goods)">{{ goods.name }}</el-radio>
          </div>
          <div class="radio-option-num" v-if="pay_mode == 1">
            total:{{ goods.times || '0' }}-used:{{ goods.used || '0' }}
          </div>
          <div class="radio-option-num" v-else></div>
        </div>
      </div>
      <div class="footer-btn">
        <el-button v-if="type === 'update'" @click="cancelEdit" class="prev qjc-fts-16 qjc-block">{{ $t('open.t12')
          }}</el-button>

        <el-button @click="next" class="next qjc-fts-16 qjc-block">{{ $t('open.t13') }}</el-button>

      </div>
    </div>
  </div>
</template>

<script>

import QuestionBj from './QuestionBj'

import QuestionFb from './QuestionFb'
import QuestionM22 from './QuestionM22'

import QuestionMb from './QuestionMb'
import QuestionZhihui from './QuestionZhihui'
import {
  getUserGoodsList, packageOpen
} from '@/utils/axios'
import { formData_default, formData_bj_default, formData_m22_default, formData_zhihui_default, formData_mb_default } from './question'

export default {
  name: 'NewQuestion',
  components: { QuestionBj, QuestionFb, QuestionM22, QuestionMb, QuestionZhihui },
  data () {
    return {
      formData: {},
      goodsList: [],
      pay_mode: "",
      goods_type: "",
      goods_id: ""
    }
  },
  props: {
    type: {
      type: String,
      default: () => 'insert'
    },
    cancelEdit: {
      type: Function,
      default: () => { }

    },
    dataId: {
      type: String,
      default: () => ""
    },
    isBo: {
      type: Boolean,
      default: () => false
    }
  },
  created () {
    if (this.type === 'update') {
      if (this.dataId) {
        this.getReportInfo(this.dataId)
      }
    } else {
      this.getGoodsList();

    }
  },
  mounted () { },
  watch: {},
  methods: {
    async getReportInfo (dataId) {

      packageOpen({ act: 'query', id: dataId }).then((res) => {
        if (res.code == 200) {
          let dataBase = JSON.parse(window.atob(res.data))
          let input_data = dataBase.input_data
          if (input_data.birth.split('/').length <= 1) {
            let newArr = input_data.birth.split('')
            newArr.splice(4, 0, '/')
            newArr.splice(-2, 0, '/')
            input_data.birth = newArr.join('')
          }
          if (input_data.examdate && input_data.examdate.split('/').length <= 1) {
            let newArr = input_data.examdate.split('')
            newArr.splice(4, 0, '/')
            newArr.splice(-2, 0, '/')
            input_data.examdate = newArr.join('')
          }

          let data = { ...dataBase, ...input_data }
          delete data.input_data

          let formData = {}

          if (this.isBo) {
            data.examine = [
              { "ne": "WBC", "ve": "", "ut": "A" },
              { "ne": "HGB", "ve": "", "ut": "A" },
              { "ne": "PLT", "ve": "", "ut": "A" },
              { "ne": "FG", "ve": "", "ut": "B" },
              { "ne": "HBA1C", "ve": "", "ut": "A" },
              { "ne": "CHOL", "ve": "", "ut": "B" },
              { "ne": "LDLC", "ve": "", "ut": "B" },
              { "ne": "HDLC", "ve": "", "ut": "B" },
              { "ne": "TG", "ve": "", "ut": "B" },
              { "ne": "GOT", "ve": "", "ut": "A" },
              { "ne": "GPT", "ve": "", "ut": "A" },
              { "ne": "ALP", "ve": "", "ut": "A" },
              { "ne": "ALB", "ve": "", "ut": "B" },
              { "ne": "CRE", "ve": "", "ut": "B" },
              { "ne": "UA", "ve": "", "ut": "B" },
              { "ne": "EGFR", "ve": "", "ut": "A" }
            ]
            formData = data
            if (formData.yy_user_id) {
              formData.yyuserid = formData.yy_user_id
            }
          } else {
            formData = data
            if (formData.yy_user_id) {
              formData.yyuserid = formData.yy_user_id
            }
          }
          if (formData.type == "AIFB") {
            formData.fbCheckList = formData.disease.filter(item => item.level == 1).map(d => d.fdis)
            formData.serviceCheckList = formData.service.filter(item => item.need == 2).map(d => d.svc)
          }
          if (formData.type == "AIMB") {
            formData = {
              ...formData,
              ...formData.babyinf,
              ...formData.preinf
            }
          }
          this.goods_type = formData.type
          this.goods_id = formData.goods_id
          formData.goods_type = formData.type
          if (formData.examdate === undefined) {
            formData.examdate = ""
          }
          console.log("formData", formData);
          this.formData = formData
        }
      })

    },
    async getGoodsList () {
      const res = await getUserGoodsList()
      if (res.code === 200) {
        let data = JSON.parse(window.atob(res.data))
        this.goodsList = data.goodsList
        let list = this.$t('goodsList')
        this.goodsList = this.goodsList.map(item => {
          const arr = list.filter(l => l.type == item.type)
          if (arr.length > 0) {
            item.name = arr[0].name
          }
          return item
        })
        this.pay_mode = data.pay_mode
      }
    },
    handlePackage (goods) {
      this.goods_id = goods.goods_id

    },
    resetFormData (data) {
      this.formData = JSON.parse(JSON.stringify(data || {}))
      this.goods_type = ""
      this.goods_id = ""
      if (this.type === 'update') {
        this.cancelEdit()
      } else {
        this.getGoodsList()
      }
    },
    next () {
      const arr = this.goodsList.filter(item => item.goods_id == this.goods_id)
      if (arr.length > 0) {
        const goods_type = arr[0].type
        this.goods_type = goods_type
        if (goods_type == 'AIBG') {
          this.formData = JSON.parse(JSON.stringify(formData_bj_default))
        } else if (goods_type == 'M22') {
          this.formData = JSON.parse(JSON.stringify(formData_m22_default))
        } else if (goods_type == 'AIZH') {
          this.formData = JSON.parse(JSON.stringify(formData_zhihui_default))
        } else if (goods_type == 'AIMB') {
          this.formData = JSON.parse(JSON.stringify(formData_mb_default))
        } else {
          this.formData = JSON.parse(JSON.stringify(formData_default))
        }
        this.formData.goods_type = goods_type
        this.formData.goods_id = this.goods_id


      }
    }
  }
}
</script>


<style>
.el-step__icon {
  border: none !important;
  width: 36px !important;
  height: 36px !important;
}

.el-step__title {
  color: #333 !important;
  font-weight: normal !important;
  font-size: 12px;
}


.form-header-title {
  position: relative;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 4px solid #6883FB;
}

.insert-title {
  position: relative;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #293033;
  line-height: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E5E8F5;
  margin-bottom: 4px;
  margin-top: 20px;


}

.insert-title::before {
  position: absolute;
  left: -24px;
  top: 0;
  content: '';
  width: 4px;
  height: 20px;
  background-color: #6883FB;
}
</style>

<style scoped>
.new-question>>>.el-radio__label {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.new-question {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  >div {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 40px;

    .package-select-content {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 0;

      .package {
        width: 20%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-right: 2%;
        padding: 8px 20px;
        border: 1px solid #00000020;
        min-width: 420px;
        border-radius: 4px;
        cursor: pointer;

        >div {
          overflow: hidden;

          &:first-child {
            flex: 7;
          }

          &:last-child {
            flex: 5;
            text-align: right;
          }
        }

        &:hover {
          border-color: #6883FB;
        }

        .radio-option-num {
          font-size: 14px;
        }
      }

      .radio-option-num {
        white-space: nowrap;
      }
    }
  }

  .package-select {
    margin-top: 10%;

    .insert-title {
      font-weight: bold;
    }
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 400;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }


  .footer-btn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


}
</style>