<template>
  <div class="bodily">
    <!-- <h3 class="qjc-fts-16 qjc-c-dark qjc-ftw-500">新生儿信息</h3> -->

    <el-form :model="formData" :rules="rules" inline :hide-required-asterisk="true" ref="newbornForm">
      <el-row :gutter="16">
        <el-col :span="24">
          <div class="insert-title">宝宝信息</div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="妈妈的生育年龄：" prop="mage">
            <el-input v-model="formData.mage" placeholder="请输入">
              <template slot="append">岁</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宝宝的出生孕周：" prop="week">
            <el-input v-model="formData.week" placeholder="请输入">
              <template slot="append">周</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宝宝的出生体重：" prop="bweight">
            <el-input v-model="formData.bweight" placeholder="请输入">
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="12">
            <el-form-item label="宝宝的性别：" prop="bsex">
              <el-select v-model="formData.bsex">
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="12">
            <el-form-item label="APGAR分数：" prop="aps1">
              <el-input v-model="formData.aps1" placeholder="请输入">
                <template slot="append">分</template>
              </el-input>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-block">下一步</el-button>
    </div>
    <!-- <el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button> -->

  </div>
</template>

<script>
let numberInput = function (value) {
  var price = '' + value;
  price = price
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  if (price.indexOf('.') < 0 && price != '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    price = parseFloat(price);
  }
  return price
}
export default {
  name: 'newborn',
  data () {
    var apgarVerify = (rule, value, callback) => {
      if (numberInput(value)) {
        if (numberInput(value) < 0 || numberInput(value) > 10) {
          callback(new Error('APGAR分数——即新生儿评分(第一分钟)为0~10'));
        } else {
          callback();
        }
      } else {
        callback(new Error('APGAR分数——即新生儿评分(第一分钟)为0~10'));
      }
    }
    var weekVerify = (rule, value, callback) => {
      if (numberInput(value)) {
        callback();
      } else {
        callback(new Error('请输入宝宝正确的的出生孕周'));
      }
    }
    var mageVerify = (rule, value, callback) => {
      if (numberInput(value)) {
        callback();
      } else {
        callback(new Error('请输入妈妈正确的生育年龄'));
      }
    }
    var bweightVerify = (rule, value, callback) => {
      if (numberInput(value)) {
        callback();
      } else {
        callback(new Error('请输入宝宝正确的出生体重'));
      }
    }

    return {
      rules: {
        bsex: [{
          required: true,
          message: '请选择入宝宝性别',
          trigger: 'change'
        }],
        mage: [{
          required: true,
          message: '请输入妈妈的生育年龄',
          trigger: 'blur'
        },
        {
          validator: mageVerify,
          trigger: 'blur'
        }
        ],
        week: [{
          required: true,
          message: '请输入宝宝的出生孕周：',
          trigger: 'blur'
        },
        {
          validator: weekVerify,
          trigger: 'blur'
        }
        ],
        bweight: [{
          required: true,
          message: '请输入宝宝的出生体重：',
          trigger: 'blur'
        },
        {
          validator: bweightVerify,
          trigger: 'blur'
        }
        ],
        aps1: [{
          required: true,
          message: '请输入APGAR分数——即新生儿评分(第一分钟)',
          trigger: 'blur'
        },
        {
          validator: apgarVerify,
          trigger: 'blur'
        }
        ],
      }
    }
  },
  props: ['formData'],
  methods: {
    next () {
      this.$refs.newbornForm.validate((isSuc) => {
        if (isSuc) {
          this.$emit('nextStep');
        }
      });
    },
    handlePrev () {
      this.$emit('prevStep');
    },
  }
}
</script>

<style scoped>
.el-select>>>.el-input__inner {
  width: 100%;
}

.el-form>>>.el-form-item__content,
.el-form>>>.el-form-item__label {
  vertical-align: middle;
}

.el-form>>>.el-form-item__label {
  width: 100%;
  text-align: left;
}

.el-row>>>.el-form-item__content {
  width: 100%;
}

.el-row>>>.el-input,
.el-select {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.bodily {
  padding: 10px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form-item {
    width: 100%;


  }

  .next-form-item {
    margin-right: 0 !important;
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }

  .el-form {
    margin-top: 14px;

    .el-form-item {
      margin-bottom: 20px;

      &.apgar {
        // margin-left: 0.2px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }


    }
  }

  .item-100 {
    width: 100%;
  }


}
</style>