<template>
  <div class="bodily">
    <div class="insert-title">妈妈信息</div>

    <h4 class="qjc-fts-14 qjc-c-dark qjc-ftw-500">产前两年内是患过下列疾病？(多选)</h4>
    <el-radio v-model="hasPredis2" label="0" class="qjc-wid-100">无</el-radio>
    <el-radio v-model="hasPredis2" label="1" class="qjc-wid-100">有，请选择（多选）</el-radio>

    <el-checkbox-group v-model="formData.predis2" :disabled="hasPredis2 == 0 ? true : false">
      <el-checkbox v-for="item in list" :key="item.val" :label="item.val" :title="item.name">{{ item.name
        }}</el-checkbox>
    </el-checkbox-group>

    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-block">下一步</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'puerpera',
  data () {
    return {
      list: [
        { val: 'DIS01', name: '卵巢功能障碍' },
        { val: 'DIS02', name: '感冒>=3次' },
        { val: 'DIS03', name: '头颈疾病' },
        { val: 'DIS04', name: '营养缺乏' },
        { val: 'DIS05', name: '高血压疾病' },
        { val: 'DIS06', name: '慢性肝病' },
        { val: 'DIS07', name: '消化道疾病<3次' },
        { val: 'DIS08', name: '流产' },
        { val: 'DIS09', name: '脑血管疾病' },
        { val: 'DIS10', name: '女性生殖器官肿瘤' },
        { val: 'DIS11', name: '关节疾患' },
        { val: 'DIS12', name: '糖尿病' },
        { val: 'DIS13', name: '慢性阻塞性肺部疾病(COPD)' },
        { val: 'DIS14', name: '泌尿系统疾患(除肾脏外)' },
        { val: 'DIS15', name: '肾脏疾病' },
        { val: 'DIS16', name: '子宫、卵巢相关癌症' },
        { val: 'DIS17', name: '妊娠疾病' },
        { val: 'DIS18', name: '癫痫' },
        { val: 'DIS19', name: '精神疾病+忧郁症' },
        { val: 'DIS20', name: '甲状腺疾患' },
        { val: 'DIS21', name: '眼部疾患' },
        { val: 'DIS22', name: '感冒<3次' },
        { val: 'DIS23', name: '贫血' },
        { val: 'DIS24', name: '肥胖及营养过度' },
        { val: 'DIS25', name: '皮肤疾病' },
        { val: 'DIS26', name: '恶性肿瘤' },
        { val: 'DIS27', name: '心脏病' },
        { val: 'DIS28', name: '消化道疾病>=3次' },
        { val: 'DIS29', name: '女性生理期疾患' },
        { val: 'DIS30', name: '头痛' },
        { val: 'DIS31', name: '痔疮' },
        { val: 'DIS32', name: '口腔疾患>=3次' },
        { val: 'DIS33', name: '痛风' },
        { val: 'DIS34', name: '良性肿瘤' },
        { val: 'DIS35', name: '乳癌' },
        { val: 'DIS36', name: '腹部骨盆疾病' },
        { val: 'DIS37', name: '乳房疾患' },
        { val: 'DIS38', name: '霉菌病' },
        { val: 'DIS39', name: '口腔疾患<3次' },
        { val: 'DIS41', name: '肺炎' }
      ],

      hasPredis2: '0'// 是否患病

    }
  },
  props: ['formData', "type"],
  created () {
    this.hasPredis2 = (this.formData.predis2 && this.formData.predis2.length > 0) ? '1' : '0'// 是否患病
  },
  watch: {
    hasPredis2 (val) {// 选择无疾病时清空已选项
      if (val == '0') {
        this.formData.predis2 = [];
      }
    }
  },
  methods: {
    next () {
      if (this.hasPredis2 == '0' && this.formData.predis2.length > 0) {
        this.$myAlert({
          type: 'fail',
          title: '选择错误'
        });
      } else if (this.hasPredis2 == '1' && this.formData.predis2.length == 0) {
        this.$myAlert({
          type: 'fail',
          title: '选择错误',
          content: '请选择至少一种疾病或无患病'
        });
      } else {
        this.$emit('nextStep');
      }
    },
    handlePrev () {
      this.$emit('prevStep');
    },
  }
}
</script>

<style scoped>
.el-radio>>>.el-radio__label,
.el-checkbox>>>.el-checkbox__label {
  font-size: 14px;
  line-height: 14px;
  color: #777F8F;
}

.el-checkbox>>>.el-checkbox__label {
  padding-left: 6px;
}

.el-form>>>.el-radio.is-bordered.is-checked {
  border-color: #6681FA
}
</style>
<style lang="scss" scoped>
.bodily {
  padding: 10px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  &>h4 {
    margin: 24px 0 16px;
  }

  .el-radio {
    margin-bottom: 16px;
    margin-right: 20px;
  }

  .el-checkbox-group {
    background-color: #F2F4FD;
    border: 1px solid #E5E9F9;
    border-radius: 4px;

    .el-checkbox {
      width: 16.666%;
      line-height: 14px;
      margin-right: 0;
      padding: 11px 16px;
      border-bottom: 1px solid #fff;
      overflow: hidden;

      &:not(:nth-child(6n)) {
        border-right: 1px solid #fff;
      }
    }
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    width: 96px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }
}
</style>
